import React from "react";
import Navbar from "../header/Navbar";
import "../../assets/style/solution.scss";
import Clientele from "../home/Clienttele";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
import MembershipSlider from "../home/Membership";
// import TechnologyHeading from "../home/TechnologyHeading";

const Automotive = () => {
  return (
    <>
      <div>
        <div className="flex items-center justify-start automative-solution-header">
          <div className="container">
            <h1 className="text-white uppercase lg:text-4xl text-2xl font-bold">
              NextGen Connected Vehicles <br className="xs-hidden" />
              And Infotainment Solutions
            </h1>
          </div>
        </div>
        <div className="flex w-full justify-center items-center lg:py-16 py-8 automative-contents">
          <div className="container lg:flex ">
            <div className="lg:w-1/2 w-full">
              <h4 className="text-3xl font-semibold uppercase pb-4">
                AUTOMOTIVE
              </h4>
              <p className="lg:text-xl text-lg pb-2 align-justify">
                The global automotive industry is experiencing a paradigm shift
                due to the increasing adoption of digital technologies. We are
                at a tipping point where the fundamental construct of the
                automotive industry is being redefined due to the
                transformational shift from mechanical vehicles to real-time
                data-driven smart mobility experiences.
              </p>
              <p className="lg:text-xl pt-4 text-lg align-justify">
                This new approach will also require automotive suppliers to work
                towards developing a customer-centric, digital DNA across the
                supply chain. Even aftermarket services, such as service request
                management, warranty management and vehicle diagnostics are
                moving towards digital automation and are readily accessible to
                customers at their fingertips. We help automotive players to
                accelerate the whole customer lifecycle with new experiences,
                helping them cut costs, transform, and modernize in a way that
                it will support them in their evolving automotive business.
              </p>
            </div>
            <div className="lg:w-1/2 w-full  lg:pl-16 pt-16">
              <img
                src="../images/automotive/automotive.png"
                alt=""
                style={{ maxHeight: "350px", width: "470px" }}
              />
            </div>
          </div>
        </div>

        <div className=" what-we-offer pb-8">
          <div className="container">
            <h4 className="font-semibold text-2xl uppercase pb-4">
              What we offer
            </h4>
            <Row className="flex" gutter={[16, 24]}>
              <Col lg={12} xs={24} className="text-lg">
                <p>
                  <span>ADAS & Autonomous Vehicles:</span> Algorithm
                  optimization, Porting, Testing, Sensor experience (Camera,
                  RADAR, pDAR)
                </p>
                <p className="pt-2">
                  <span>Connected Vehicles:</span> Sensor integration, V2V/V2X,
                  remote diagnostics, cloud-based analytics.
                </p>
                <p className="pt-2">
                  <span>Electric Vehicles Solutions:</span> Charging station
                  (design, hardware, software, and testing)
                </p>
                <p className="pt-2">
                  <span>Telematics:</span> Hardware & Firmware development, GPS,
                  Navigation, Fleet management (e.g., vehicle tracking,
                  surveillance)
                </p>
              </Col>
              <Col
                lg={12}
                xs={24}
                className="text-lg solution-items pt-0"
                style={{
                  marginLeft: "8px !important",
                  paddingTop: "0px !important",
                }}
              >
                <p>
                  <span> Integrated Cockpit:</span> Hardware design, ECU
                  software, HMI & application, platform development,
                  verification & validation
                </p>
                <p className="pt-2">
                  <span>Multimedia:</span> Audio/Video Codecs, Processing
                  algorithms, Interfaces & driver development
                </p>
                <p className="pt-2">
                  <span>AUTOSAR:</span> Stack (BSW, RTE, COM) configuration &
                  integration, MCAL development, BSP & Device driver, AUTOSAR
                  migration / upgradation
                </p>
                <p className="pt-2">
                  <span>Digital Transformation:</span> Cloud based Integration
                  and deployments in-vehicle apps and external mobile apps with
                  cloud automotive services & Supply chain solutions.
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="flex why-us  items-center py-8">
        <div className="container">
          <h4 className="text-2xl font-bold uppercase">Why Us</h4>
          <Row
            gutter={[16, 24]}
            className="py-4 flex justify-center items-center"
          >
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../../images/automotive/industry.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  80+ years of Industry experience in automotive technologies
                  from bus protocols and ECUs to applications and infotainment
                  solutions
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../../images/automotive/expertise.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Expertise in multiple Industry vertices and leading platforms
                  to explore new opportunities
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../../images/automotive/rocket.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Shortening your time from initial concept, planning and
                  implementation to delivery using our best practices
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../../images/automotive/microchip.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Expertise across Automotive operating platform such as
                  Android, Tizen, Linux, Windows embedded & QNX
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in Automotive technology." /> */}
      <ReachOut />
    </>
  );
};

export default Automotive;
