import React from "react";
import "../../assets/style/career.scss";
import Navbar from "../header/Navbar";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import jobData from "./JobData";
import "../../assets/style/industry.scss";

import JobCard from "./JobCard";

const Career = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  // const onChangeGenderHandler = (e) =>{
  //   console.log(e);
  // }

  // const onChangeLocationHandler = (e) =>{

  // }

  return (
    <>
      <div className="flex items-center career-section">
        <div className="container">
          <h1 className="text-white font-bold lg:text-5xl text-2xl uppercase">
            Careers
          </h1>
        </div>
      </div>
      <div
        className="flex justify-center text-center items-center"
        style={{ background: "rgb(249 249 249)" }}
      >
        <div className="container pt-8">
          <h4 className="lg:text-4xl text-2xl text-primary mb-0">
            We are Hiring
          </h4>
          <p className="pt-4" style={{ fontSize: "17px" }}>
            Below are our current Job Openings
          </p>
        </div>
      </div>

      <div
        className="flex flex-row justify-center items-center py-8 job-card"
        style={{ background: "rgb(249 249 249)" }}
      >
        <div className="container">
          <Slider {...settings} className="job-posting-slider">
            <div className="career-slider">
              <JobCard
                jobId={jobData[0].jobId}
                jobName={jobData[0].jobName}
                experience={jobData[0].experience}
                employeeStatus={jobData[0].employeeStatus}
              />
              <br />
              <JobCard
                jobId={jobData[1].jobId}
                jobName={jobData[1].jobName}
                experience={jobData[1].experience}
                employeeStatus={jobData[1].employeeStatus}
              />
            </div>
            <div className="career-slider">
              <JobCard
                jobId={jobData[2].jobId}
                jobCode={jobData[2].jobCode}
                jobName={jobData[2].jobName}
                experience={jobData[2].experience}
                employeeStatus={jobData[2].employeeStatus}
              />
              <br />
              <JobCard
                jobId={jobData[3].jobId}
                jobCode={jobData[3].jobCode}
                jobName={jobData[3].jobName}
                experience={jobData[3].experience}
                employeeStatus={jobData[3].employeeStatus}
              />
            </div>

            <div className="career-slider">
              <JobCard
                jobId={jobData[4].jobId}
                jobName={jobData[4].jobName}
                experience={jobData[4].experience}
                employeeStatus={jobData[4].employeeStatus}
              />
              <br />
              <JobCard
                jobId={jobData[5].jobId}
                jobName={jobData[5].jobName}
                experience={jobData[5].experience}
                employeeStatus={jobData[5].employeeStatus}
              />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Career;
