import React from "react";
import Navbar from "../header/Navbar";
import "../../assets/style/solution.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
// import TechnologyHeading from "../home/TechnologyHeading";

const ATSC3 = () => {
  return (
    <>
      <div className="flex items-center justify-start atsc-header-title">
        <div className="container">
          <h1 className="text-primary uppercase lg:text-5xl text-2xl font-bold px-16">
            ATSC 3.0 The next <br className="xs-hidden" />
            leap in TV services
          </h1>
        </div>
      </div>
      <div className="flex w-full justify-center items-center lg:py-16 py-8 atsc-contents">
        <div className="container lg:flex ">
          <div className="lg:w-1/2 w-full">
            <h4 className="text-3xl font-semibold uppercase pb-4">
              AWS Digicaster
            </h4>
            <p className="lg:text-xl text-lg align-justify">
              ATSC 3.0 Deployment made Simpler.
              <br /> OptM enables broadcasters to speed up the transition of
              over-the-air delivery to ATSC 3.0 “NextGen” television services,
              partnering with Digicap and ATEME. It also helps to migrate to
              Next Gen TV Services via cloud platform seamlessly.
            </p>
            <p className="lg:text-xl text-lg align-justify pt-8">
              The “NextGen” standard starts by delivering conventional, linear
              television programming with exceptional vigor and the platform
              opens vast new monetizing opportunities to broadcasters by
              enabling non-television data distribution that leverages an
              enormously efficient one-to-many architecture. This architecture
              is designed to be a complementary back haul, aligned with 5G
              technologies of the future.
            </p>
          </div>
          <div className="lg:w-3/2 w-full lg:pt-16 lg:pl-8 pt-8 ">
            <img src="../images/atsc/digicaster1.jpg" alt="" />
          </div>
        </div>
      </div>
      <Clientele />
      {/* <TechnologyHeading text="Take the next step in Broadcaster technology." /> */}
      <MembershipSlider />
      <ReachOut />
    </>
  );
};

export default ATSC3;
