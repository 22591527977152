import React from "react";
import Slider from "react-slick";
import { Card } from "antd";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { AiOutlineArrowRight } from "react-icons/ai";
const ProductSlider = () => {
  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4500, //milliseconds
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <>
      <div className="flex bg-grey justify-center items-center lg:py-16 py-8">
        <div className="container">
          <h4 className="lg:text-2xl text-xl uppercase font-semibold lg:px-8 py-4">
            Our Services
          </h4>
          <Slider {...settings}>
          <div className="lg:w-1/3 w-full">
            <Link to="/qt">
              <Card
                className="product-card"
                style={{ width: "78%" }}
                cover={<img alt="" src="../images/product-slider/qt.png" />}
              >
                <h4 className="text-xl text-primary font-bold">Qt</h4>
                <p
                  className="text-lg product-contents"
                  style={{ textAlign: "justify" }}
                >
                  Build your native applications and more engaging UIs.
                  Comprehensive Qt & QML consulting for any platform, any
                  size, any complexity and for any stage.
                </p>
                <div className="view-more-link">More...</div>
              </Card>
            </Link>
          </div>
          <div className="lg:w-1/3 w-full">
            <Link to="/embeded">
              <Card
                className="product-card"
                style={{ width: "78%" }}
                cover={
                  <img
                    alt="example"
                    src="../images/product-slider/embedded-systems.png"
                  />
                }
              >
                <h4 className="text-xl uppercase text-primary font-bold pb-2">
                  Embedded Systems
                </h4>
                <p
                  className="text-lg product-contents"
                  style={{ textAlign: "justify" }}
                >
                  Leverage our vast embedded design knowledge to fully
                  optimize your product development. Accelerate the R&D cycle
                  and drive new product development. True partner for your
                  success.
                </p>
                <div className="view-more-link">More...</div>{" "}
                {/* View More <AiOutlineArrowRight className="arrow-left " /> */}
              </Card>
            </Link>
          </div>
          <div className="lg:w-1/3 w-full">
              <Link to="/cloud">
                <Card
                  className="product-card"
                  style={{ width: "78%" }}
                  cover={
                    <img alt="" src="../images/product-slider/cloud.png" />
                  }
                >
                  <h4 className="text-xl uppercase text-primary font-bold pb-2">
                    CLOUD
                  </h4>
                  <p
                    className="text-lg product-contents"
                    style={{ textAlign: "justify" }}
                  >
                    Asses your cloud and critical security requirement to
                    accelerate your business with Digital Transformation.
                    Recommend, Remediation and Re-architect to maximize
                    efficiency.
                  </p>
                  <div className="view-more-link">More...</div>
                  {/* <AiOutlineArrowRight className="arrow-left " /> */}
                </Card>
              </Link>
            </div>
            <div className="lg:w-1/3 w-full">
              <Link className="view-more-link" to="/ai-ml">
                <Card
                  className="product-card"
                  style={{ width: "78%" }}
                  cover={<img alt="" src="../images/product-slider/AI.png" />}
                >
                  <h4 className="text-xl uppercase text-primary font-bold pb-2">
                    AI & ML
                  </h4>
                  <p
                    className="text-lg product-contents"
                    style={{ textAlign: "justify" }}
                  >
                    Amalgamation of edge and Cloud Computing with AI & Machine
                    Learning capabilities to automate & accelerate
                    Organization’s Objectives.
                  </p>
                  <div className="view-more-link">More...</div>{" "}
                  {/*  <AiOutlineArrowRight className="arrow-left " /> */}
                </Card>
              </Link>
            </div>
            <div className="lg:w-1/3 w-full">
              <Link to="/android">
                <Card
                  className="product-card"
                  style={{ width: "78%" }}
                  cover={
                    <img alt="" src="../images/product-slider/android.png" />
                  }
                >
                  <h4 className="text-xl uppercase text-primary font-bold pb-2">
                    Android
                  </h4>
                  <p
                    className="text-lg product-contents"
                    style={{ textAlign: "justify" }}
                  >
                    Take the advantage of all the features that make android the
                    world’s most popular platform.
                  </p>
                  <div className="view-more-link">More...</div>{" "}
                  {/* <AiOutlineArrowRight className="arrow-left " /> */}
                </Card>
              </Link>
            </div>
            <div className="lg:w-1/3 w-full">
              <Link to="/bigdata">
                <Card
                  className="product-card"
                  style={{ width: "78%" }}
                  cover={
                    <img
                      alt="example"
                      src="../images/product-slider/bigdata.png"
                    />
                  }
                >
                  <h4 className="text-xl uppercase text-primary font-bold pb-2">
                    Bigdata
                  </h4>
                  <p
                    className="text-lg product-contents"
                    style={{ textAlign: "justify" }}
                  >
                    Address your complex business challenges of large data set
                    into actionable insights to maximize your return on
                    investments.
                  </p>
                  <div className="view-more-link">More...</div>{" "}
                  {/* <AiOutlineArrowRight className="arrow-left " /> */}
                </Card>
              </Link>
            </div>
            
            <div className="lg:w-1/3 w-full">
              <Link to="/digital-signal-processing">
                <Card
                  className="product-card"
                  style={{ width: "78%" }}
                  cover={<img alt="" src="../images/product-slider/dsp.png" />}
                >
                  <h4 className="text-xl uppercase text-primary font-bold pb-2">
                    DSP
                  </h4>
                  <p
                    className="text-lg product-contents"
                    style={{ textAlign: "justify" }}
                  >
                    Experience and expertise to develop, integrate and optimize
                    the algorithms. Planned concepts are quickly translated into
                    successful products.
                  </p>
                  <div className="view-more-link">More...</div>{" "}
                  {/* <AiOutlineArrowRight className="arrow-left " /> */}
                </Card>
              </Link>
            </div>
            
            <div className="lg:w-1/3 w-full">
              <Link to="/microservices">
                <Card
                  className="product-card"
                  style={{ width: "75%" }}
                  cover={
                    <img
                      alt=""
                      src="../images/product-slider/microservices.png"
                    />
                  }
                >
                  <h4 className="text-xl uppercase text-primary font-bold pb-2">
                    Microservices
                  </h4>
                  <p
                    className="text-lg product-contents"
                    style={{ textAlign: "justify" }}
                  >
                    Align and improve your business and IT functionality,
                    technology agility, and enable incremental and continuous
                    software deployment.
                  </p>
                  <div className="view-more-link">More...</div>{" "}
                  {/* View More <AiOutlineArrowRight className="arrow-left " /> */}
                </Card>
              </Link>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};
export default ProductSlider;
