import React from "react";
import "../../assets/style/solution.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";

const BAAS = () => {
  return (
    <>
      <div className="pb-8">
        <div className="flex items-center baas-banner">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-xl ">
              BROADCAST AS <br className="xs-hidden" /> A SERVICE
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="text-3xl font-bold text-center pb-4">NEXT GEN BROADCAST AS A SERVICE</h4>
            <div className="flex justify-center">
                <img src="../images/baas/digicaster-design.png" alt="" />
            </div>
          </div>
        </div>
        <div className="flex pl-12 justify-center items-center ">
          <div className="container">
            <Row className="flex" gutter={[16, 24]} align="top">
              <Col lg={10} xs={24} className="text-lg">
                {/* <h4 className="font-semibold text-2xl uppercase pb-4">
                  DIGICASTER
                </h4> */}
                <p class="text-lg align-justify pt-1">
                    Cloud Platform to process, secure, distribute
                    and monetize NextGen TV ATSC 3.0 Broadcast.
                </p>
                <p className="text-lg align-justify pt-1">
                    Delivering compelling Multiscreen Video experiences like 4K/8K 
                    UHD broadcast & Dolby ATMOS experience using ATSC 3.0 Gateway.
                </p >
                <p className="align-justify pt-1">
                    Secure Premium Video experiences across all screens via A3SA.
                </p>
                <p className="text-lg align-justify pt-1">
                    Datacasting of generic files using ATSC 3.0 NRT services.
                </p>
              </Col>
              <Col lg={1} xs={24} className="pr-4"></Col>
              <Col lg={10} s={24} className="text-lg" style={{alignItems: 'top'}}>
                <p class="text-lg align-justify pt-1">
                    Simple, advanced automation, and transparency to the entire 
                    broadcast operations workflow.
                </p>
                <p className="text-lg align-justify pt-1">
                    Fully transparent operations with web UI access to customer.
                </p >
                <p className="align-justify pt-1">
                    APIs for seamless integration of Broadcast & Broadband integration & Offloading CDN
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in broadcast technology." /> */}
      <ReachOut />
    </>
  );
};

export default BAAS;
