import React from "react";
const Solution = () => {
  return (
    <>
      <div className="flex flex-row flex-col justify-center items-center  py-8">
        <div className="container lg:flex ">
          <div className="lg:w-2/5 py-8 w-full ">
            <img alt="solution" src="../images/leaders.png" className="" />
          </div>
          <div className="lg:w-3/5 w-full pt-8 lg:px-10 order-1 lg:order-2">
            <h4 className="lg:text-3xl text-2xl uppercase text-primary font-semibold pb-2">
              Global Leader
            </h4>
            <p
              className="text-lg pb-2"
              style={{ textAlign: "justify" }}
            >
              OptM is the Leading Technology and Services provider across industries, including 
              Automotive, Media & Broadcast, Healthcare, and Defense Electronics. Our skilled and 
              certified resources have extensive knowledge and an impressive track record of task 
              completion. OptM with expertise in design and Integrating digital technology, creating 
              sustainable value growth through a relentless drive towards innovations and unique partnerships.
            </p>
            <p
              className="text-lg pb-2"
              style={{ textAlign: "justify" }}
            >
              We closely work with our clients to understand their digital transformation journey to build 
              innovative custom solutions that match their unique business needs and help them to attain their 
              business goals.
            </p>
            {/* <p className="text-lg font-semibold pt-4">We expertise in:</p>
            <div className="flex solutions pb-8">
              <ul className="lg:w-1/3 w-full text-lg ml-4 solution-items">
                <li>AI & ML</li>
                <li>DSP </li>
                <li>Android</li>
                <li>Microservices</li>
              </ul>
              <ul className="lg:w-1/3 w-full text-lg solution-items software-items">
                <li>Big Data</li>
                <li>Cloud Computing</li>
                <li>Qt</li>
                <li>Automotive</li>
              </ul>
            </div> */}
            {/* <button type="btn" className="solution-btn ">
              <Link to="/ai-ml"> Know more</Link>
            </button> */}
          </div>
        </div>
        <div className="container lg:flex lg:py-16 py-8">
          <div className="lg:w-3/5 w-full lg:px-8 lg:pt-16">
            <h4 className="lg:text-3xl text-2xl uppercase text-primary font-semibold pb-2">
              People Centric Organization
            </h4>
            <p
              className="text-lg leading-loose"
              style={{ textAlign: "justify" }}
            >
              Committed to providing equal opportunity, diversity, meritocracy, and collaboration. 
              A culture of recognising and rewarding performance has helped our customers achieve 
              tremendous and consistent success.
            </p>

            {/* <p className="text-lg font-semibold pt-4">We expertise in:</p>

            <div className="flex solutions px-8 pb-8">
              <ul className="lg:w-1/3 w-full text-lg solution-items">
                <li>Embedded Systems</li>
                <li>Automotive</li>
                <li>Semiconductor</li>
                <li>Medical Device</li>
              </ul>
              <ul className="lg:w-1/3 w-full text-lg solution-items">
                <li>ATSC 3.0</li>
                <li>QT</li>
                <li>Defense Electronics</li>
                <li>Media and Broadcast</li>
              </ul>
            </div> */}
            {/* <button type="btn" className="solution-btn ml-4">
              <Link to="/automative"> Know more</Link>
            </button> */}
          </div>
          <div className="lg:w-2/5 w-full lg:py-4 py-8">
            <img
              alt="hardware"
              src="../images/people-centric.png"
              className=""
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Solution;
