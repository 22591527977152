import React from "react";
import "../../assets/style/services.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const QT = () => {
  return (
    <>
      <div className="pb-8">
        <div className="flex items-center qt-section">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-xl ">
              CONSULT LEADING <br className="xs-hidden" />
              Qt EXPERTS
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="text-3xl font-bold text-center pb-4">Qt</h4>
            <p className="text-lg  leading-loose align-justify">
              Qt is a cross-platform application framework used extensively across major industries 
              such as Automotive, Medical, Media, Military, Industrial controls, etc. It enables to build 
              better native applications and more engaging UIs on embedded, touchscreen, and desktop 
              devices due to its high-end capabilities.
            </p>
            <p className="text-lg  leading-loose align-justify">
              With a team of experts in Qt/QML, OptM holds the experience throughout all the development 
              lifecycle phases to solve even the most critical challenges in Qt development and performance 
              improvements and can move from the kernel driver upwards to your application.
            </p>
            <p className="text-lg  leading-loose align-justify">
              Are you looking to build a new product or speed up the development of an existing project? 
              OptM offers professional Qt and QML consulting services for any size and project cycle.
            </p>
          </div>
        </div>
        <div className="what-we-offer pb-8">
          <div className="container">
            <Row className="flex" gutter={[16, 24]}>
              <Col lg={12} xs={24} className="text-lg">
                <h4 className="font-semibold text-2xl uppercase pb-4">
                  What we offer
                </h4>
                <p>UX /UI and HMI & Middleware development – Qt/QML.</p>
                <p className="pt-2">
                  Architecture, Design, Development and Deployment of Integrated
                  Qt applications for Embedded, Bare Metal, Desktop & Mobile
                  devices.
                </p>
                <p className="pt-2">
                  Porting & Migration of legacy applications.
                </p>
                <p className="pt-2">Optimization & Performance improvement.</p>
                <p className="pt-2">Enhancement & Maintenance.</p>
              </Col>
              <Col lg={4} xs={24} className="text-lg ">
                <h4 className="font-semibold text-2xl text-mobile uppercase pb-4">
                  Our expertise
                </h4>
                <p>QNX</p>
                <p className="pt-2">VxWorks</p>
                <p className="pt-2">Android /iOS</p>
                <p className="pt-2">Embedded Linux</p>
              </Col>

              <Col
                lg={4}
                xs={24}
                className="text-lg mobile-margin-top "
                style={{ paddingTop: "53px" }}
              >
                <p className="">Windows Embedded</p>
                <p className="pt-2">Mac OS X</p>
                <p className="pt-2">Windows</p>
                <p className="pt-2">Linux XII</p>
              </Col>
            </Row>
          </div>
        </div>

        <div className="flex why-us  items-center py-8">
          <div className="container">
            <h4 className="text-2xl font-bold uppercase">Why Us</h4>
            <Row
              gutter={[16, 24]}
              className="py-4 flex justify-center items-center"
            >
              <Col lg={8} xs={24}>
                <div className="flex justify-center">
                  <img src="../images/qt/qt-consulting.png" alt="" />
                </div>
                <div className="flex justify-center">
                  <p className="text-lg py-4">
                    10+ years experience in Qt and QML consulting engagements
                  </p>
                </div>
              </Col>
              <Col lg={8} xs={24}>
                <div className="flex justify-center">
                  <img src="../images/qt/lisencing.png" alt="" />
                </div>
                <div className="flex justify-center">
                  <p className="text-lg py-4">
                    Independent advisor on Qt licensing and technology choices
                  </p>
                </div>
              </Col>
              <Col lg={8} xs={24}>
                <div className="flex justify-center">
                  <img src="../images/qt/augment.png" alt="" />
                </div>
                <div className="flex justify-center">
                  <p className="text-lg py-4">
                    Qt experts to augment your existing staff, or anything in
                    between
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in Qt technology." /> */}
      <ReachOut />
    </>
  );
};

export default QT;
