import React, { useState } from "react";
import "../../assets/style/solution.scss";
import "../../assets/css/products.css";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";

const I_mxrt = () => {
  const [isShow, setIsShow] = useState(false);
  const handleClick = () => {
    setIsShow((x) => !x);
  };
  return (
    <>
      <div className="main-container">
        <div className="intro-container">
          <div className="img-slider">
            {/* <img
            className="arrow"
            src="../images/automotive/L-arrow.png"
            width="50px"
            height="50px"
            onClick={() => handleClick()}
          /> */}
            <div className="images-of-prod">
              <img
                src="../images/automotive/products-img/prod2-IMXRT.png"
                width="420px"
                className={`prodimg1 ${isShow ? "show" : ""}`}
              />
              <img src="" width="500px" className="prodimg2" />
            </div>
            {/* <img
            className="arrow"
            src="../images/automotive/R-arrow.png"
            width="50px"
            height="50px"
            onClick={() => handleClick()}
          /> */}
          </div>
          <div className="content-p1">
            <h2>
              Electric Vehicle Instrument Cluster for 2 & 3 Wheelers NXP-i.MXRT
            </h2>
            <p>
              EV Instrument Cluster is based on
              <strong> NXP’s i.MXRT</strong> platform and Embedded RTOS with
              feature-rich, user-friendly and customizable HMI. The cluster can
              be customizable for 5-inch and 7-inch TFT with/without touch
              (CTP). All vehicle-related information is displayed with
              exceptional clarity, and the interaction with the cluster makes
              motorcycle riding a fascinating and safe experience with enhanced
              convenience.
            </p>
          </div>
        </div>
        <div className="spec-container">
          <h2>
            <strong>SPECIFICATIONS</strong>
          </h2>
          <div className="prod-tables">
            <h4>
              <strong>Hardware Specification</strong>
            </h4>
            <table>
              <tbody>
                <tr className="head">
                  <th>CPU</th>
                  <td>
                    <p>i.Mx RT 117x</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Frequency</th>
                  <td>
                    <p>800MHz</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>RAM Memory size</th>
                  <td>
                    <p>512MB</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Flash Memory size</th>
                  <td>
                    <p>128MB (Expandable up to 512 Mb)</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Display size & Resolution</th>
                  <td>
                    <p>800x480 for 5” / 1200x600 for 7” inch Colour TFT</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Display Touch Technology</th>
                  <td>
                    <p>Capacitive touch / Non Touch</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Display Interface</th>
                  <td>
                    <p>RGB / MIPI</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Wireless Connectivity</th>
                  <td>
                    <p>LTE, BLE v5.1, GPS</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Wired Connectivity</th>
                  <td>
                    <p>CAN 2.0B, UART, USB 2.0</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="prod-tables">
            <h4>
              <strong>Software Specification</strong>
            </h4>
            <table>
              <tbody>
                <tr>
                  <th>Operating System</th>
                  <td>
                    <p>Embedded FreeRTOS</p>
                  </td>
                </tr>
                <tr>
                  <th>Qt/ EmbeddedWizard</th>
                  <td>
                    <p>Yes</p>
                  </td>
                </tr>
                <tr>
                  <th>Power-On Splash logo</th>
                  <td>
                    <p>Customizable</p>
                  </td>
                </tr>
                <tr>
                  <th>Cluster Firmware Upgrade</th>
                  <td>
                    <p>Yes</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="prod-tables">
            <h4>
              <strong>
                Electrical Specification & Compliance Certifications
              </strong>
            </h4>
            <table>
              <tbody>
                <tr>
                  <th>Voltage range</th>
                  <td>
                    <p>8V DC – 15V DC</p>
                  </td>
                </tr>
                <tr>
                  <th>Standby Current</th>
                  <td>
                    <p>30mA (TBD)</p>
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <th>Short Circuit protection</th>
                  <td>
                    <p>Yes</p>
                  </td>
                </tr>
                <tr>
                  <th>Reverse polarity protection</th>
                  <td>
                    <p>YES (-40V to 0V)</p>
                  </td>
                </tr>
                <tr>
                  <th>Switching Spikes</th>
                  <td>
                    <p>ISO 7637-2:2004 LEVEL4</p>
                  </td>
                </tr>
                <tr>
                  <th>EMI / EMC</th>
                  <td>
                    <p>Passed Pre-Compliance</p>
                  </td>
                </tr>
                <tr>
                  <th>ESD</th>
                  <td>
                    <p>IEC 61000-4-2</p>
                  </td>
                </tr>
                <tr>
                  <th>Radiated immunity</th>
                  <td>
                    <p>IEC 61000-4-3</p>
                  </td>
                </tr>
                <tr>
                  <th>Conducted emissions</th>
                  <td>
                    <p>CISPR-22</p>
                  </td>
                </tr>
                <tr>
                  <th>Radiated Emission</th>
                  <td>
                    <p>CISPR-22</p>
                  </td>
                </tr>
                <tr>
                  <th>Surge</th>
                  <td>
                    <p>IEC 61000-4-5</p>
                  </td>
                </tr>
                <tr>
                  <th>Conducted Immunity</th>
                  <td>
                    <p>IEC 61000-4-6</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      <ReachOut />
    </>
  );
};

export default I_mxrt;
