import React, { useState } from "react";
import "../../assets/style/services.scss";
import "../../assets/style/responsive.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
import "../../assets/style/industry.scss";
// import TechnologyHeading from "../home/TechnologyHeading";
import ModalPopup from "../header/ModalPopup";
const DefenseElectronics = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = (e) => {
    setIsModalVisible(true);
  };

  const handleClose = (e) => {
    setIsModalVisible(false);
  };

  return (
    <>
      <div className="flex items-center defense-section">
        <div className="container">
          <h1 className="text-white lg:text-4xl text-xl uppercase">
            Strong Indigenous leader in
            <br className="xs-hidden" /> Defense Software development
          </h1>
        </div>
      </div>
      <div className=" py-8 justify-center items-center lg:text-center embeded-system">
        <div className="container">
          <div>
            <h4 className="uppercase text-3xl font-bold text-center pb-4">
              DEFENSE
            </h4>
            <p className="text-lg   align-justify">
              The Defense industry faces strategic challenges across air,
              ground, and marine systems. Implementing complex systems, while
              ensuring stringent product quality, security standards and
              certification criteria are critical challenges for the defense
              industry.
            </p>
            <p className="text-lg pt-4  align-justify">
              OptM with its extensive knowledge &amp; experience, in the defense
              industry and specialized teams, offers value added product
              engineering professional services, facilitating with best
              operational practice and capabilities to provide the foresight
              needed to stay competitive in the defense industry.
            </p>
          </div>
        </div>
      </div>

      <div className="what-we-offer defense-offer">
        <div className="container">
          <h4 className="font-semibold lg:text-2xl text-xl uppercase py-8 pb-4">
            Electronics Design
          </h4>
          <Row gutter={[16, 24]}>
            <Col lg={10}>
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">Embedded Hardware Design</h5>
                  <p className="text-lg">
                    Specialized in designing of embedded systems for critical
                    and extreme environments, Our designs help partner of major
                    defense groups and SMEs.
                  </p>
                  <p className="text-lg pt-2">
                    Our dedicated qualified engineers are involved in designing
                    of hardware and software.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={10} offset={2} className="mob-left">
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">Power Electronics Design</h5>
                  <p className="text-lg">
                    {" "}
                    Specialized in designing of power supplies using topologies,
                    sensor interfaces and process controls, control algorithms
                    for AC/DC drives, power device selection, along with
                    designing thermal management and EMI/EMC compliance as per
                    IEC, MIL, and SAE standards.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={24}>
              {" "}
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">FPGA Design</h5>
                  <p className="text-lg">
                    Specialized in integrated FPGA Design Sevices and FPGA
                    digital signal processing,
                  </p>
                  <p className="text-lg pt-2">
                    Providing solutions for FPGA and Signal Processing services,
                    that helps in technology progress and matching them to
                    customer specifications.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="container">
          <h4 className="font-semibold lg:text-2xl text-xl uppercase pt-8 pb-4">
            Embedded Software Development
          </h4>
          <Row gutter={[16, 24]}>
            <Col lg={24}>
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">Firmware Development</h5>
                  <p className="text-lg">
                    We offer embedded firmware development for various devices
                    running without a high-level OS like Windows or Linux.
                  </p>
                  <p className="text-lg pt-2">
                    Using either bare metal or a real-time operating system
                    (RTOS) these products or systems have certain real-time
                    capabilities that are marked by our firmware development
                    services.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={24}>
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">
                    Board Support Package Development
                  </h5>
                  <p className="text-lg">
                    OptM’s BSP and firmware development services are available
                    for various processors such as SoCs like RISC, CISC, DSP,
                    ARM, MIPS, PowerPC and x86.
                  </p>
                  <p className="text-lg">
                    Our BSP and Firmware development team gives closely observe
                    various aspects of the product like power, timing and
                    resource limitations at the time of developing the necessary
                    firmware.{" "}
                  </p>
                  <p className="text-lg pt-2">
                    Our firmware development services are provided around C/C++
                    Programming, Assembly level Programming, HDL and Python
                    along with expertise in IDE Tools and Debugging Tools.
                  </p>
                </div>
              </div>
            </Col>
          </Row>

          <h4 className="font-semibold lg:text-2xl text-xl uppercase pt-8 pb-4">
            Kernel and Driver Development
          </h4>
          <Row gutter={[16, 24]}>
            <Col lg={10}>
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">
                    Linux Kernel and Driver Development Services
                  </h5>
                  <p className="text-lg">
                    OptM execute Linux kernel driver development for embedded
                    devices running on high power processors.
                  </p>
                  <p className="text-lg pt-2">
                    Our team is having hands-on experience on modifying and
                    customizing Linux kernel, building device drivers and
                    integrating periphery.
                  </p>
                  <p className="text-lg pt-2">
                    We are engaged in Linux kernel driver programming for latest
                    devices and systems applied in various organizations.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={10} offset={2} className="mob-left">
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">
                    Embedded Linux Development Services
                  </h5>
                  <p className="text-lg">
                    We deliver optimization of Linux boot time, development and
                    debug of Linux kernel, various device drivers, Linux kernel
                    and Linux driver development for embedded devices running on
                    high-end processors.
                  </p>
                  <p className="text-lg pt-2">
                    We are having hands-on experience in developing Embedded
                    Linux Driver Development, Embedded Linux drivers for audio,
                    video, multimedia, storage, connectivity, network, wireless
                    devices for various Linux variants.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={24}>
              {" "}
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">
                    Mac/Android Driver Development Services
                  </h5>
                  <p className="text-lg">
                    Our services on Android driver development include devices
                    based periphery integration on both Android and Android
                    Things operating systems.
                  </p>
                  <p className="text-lg pt-2">
                    With our Android device driver development experience, we
                    will easily optimize your system by porting it to latest
                    versions.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="container pb-8">
          <h4 className="font-semibold lg:text-2xl text-xl uppercase pt-8 pb-4">
            System and Application Software Development
          </h4>
          <Row gutter={[16, 24]}>
            <Col lg={24}>
              <div className="flex ai-offerings">
                <div className="pl-4">
                  <h5 className="text-xl ">C/C++ Software Development</h5>
                </div>
              </div>
            </Col>
            <Col lg={24}>
              <div className="flex ai-offerings">
                <div className="pl-4">
                  <h5 className="text-xl  pb-4">Qt Software Development</h5>
                  <p className="text-lg">
                    OptM provides customized Qt Application Development services
                    for custom and generic devices with the help of the
                    cross-platform Qt Development framework.
                  </p>
                  <p className="text-lg pt-2">
                    We are expertise in UI/UX, HMI Qt Application development,
                    which helps us to collaborate with developers to build rich,
                    intuitive and compelling user interfaces for their touch
                    screen, embedded or desktop applications.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div
        className="flex why-us  items-center py-8"
        style={{ background: "#fff" }}
      >
        <div className="container">
          <h4 className="text-2xl  font-bold uppercase">Why Us</h4>
          <Row
            gutter={[16, 24]}
            className="py-4 flex justify-center items-center"
          >
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/silicon-engineering/fpga.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Complete end to end software and simulation services
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/silicon-engineering/chip.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Highly integrated on-chip solutions reduce SWaP-C for
                  multi-function & multi-wavelength sensors payloads
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/defense/radar-electronics.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Expertise in design and development of high-performance RADAR
                  electronics
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/defense/sensor.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Experts in advance smart sensors and seekers
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in Defense Electronics." /> */}
      <ReachOut />
    </>
  );
};

export default DefenseElectronics;
