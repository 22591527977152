import React from "react";
import "../../assets/style/products.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const NextGenMobile = () => {
  return (
    <>
      <div className="pb-0">
        <div className="flex items-center nextgen-mobile-banner">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-xl ">
              NEXTGEN MOBILE 5G
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="text-3xl font-bold text-center pb-4">NEXTGEN MOBILE 5G</h4>
            <p className="text-lg  leading-loose align-justify">
                5G, the fifth generation of cellular networks. Up to 100 times faster than 4G, 5G is
                creating never-before-seen opportunities for people and businesses.
            </p>
            <p className="text-lg  leading-loose align-justify">
                Faster connectivity speeds, ultra-low latency and greater bandwidth is advancing
                societies, transforming industries and dramatically enhancing day-to-day
                experiences. Services that we used to see as futuristic, such as best in class media
                streaming, e-health, connected vehicles and traffic systems and advanced
                mobile cloud gaming. With 5G technology, we can help create a smarter, safer
                and more sustainable future.
            </p>
            
          </div>
        </div>

        <div className="flex video-streaming-section items-center py-8">
            <div className="container">
                <p className="text-lg  leading-loose text-center">
                    The introduction of 5G mobile services and its promise of higher bandwidth, lower latency, and
                    network slicing brings the possibility of delivering better customer experiences. It also enables the
                    creation of new forms of media and entertainment:
                </p>
                <h4 className="text-2xl font-bold text-center uppercase">VIDEO STREAMING</h4>
                <Row
                    gutter={[16, 24]}
                    className="py-4 flex justify-center items-center"
                >
                    <Col lg={8} xs={24}>
                    <div className="flex justify-center">
                        <img src="../images/nextgen-mobile/speed.png" alt="" />
                    </div>
                    <div className="flex justify-center">
                        <p className="text-lg py-4">
                            Greater Speeds
                        </p>
                    </div>
                    </Col>
                    <Col lg={8} xs={24}>
                    <div className="flex justify-center">
                        <img src="../images/nextgen-mobile/4k-quality.png" alt="" />
                    </div>
                    <div className="flex justify-center">
                        <p className="text-lg py-4">
                            Better Quality
                        </p>
                    </div>
                    </Col>
                    <Col lg={8} xs={24}>
                    <div className="flex justify-center">
                        <img src="../images/nextgen-mobile/stream.png" alt="" />
                    </div>
                    <div className="flex justify-center">
                        <p className="text-lg py-4">
                            Stream interactive videos to larger screens
                        </p>
                    </div>
                    </Col>
                </Row>
            </div>
        </div>

        <div className="flex sdk-support-section items-center py-8">
            <div className="container">
                <h4 className="text-2xl font-bold text-center uppercase">OPTM OFFERS SDKS TO SUPPORT</h4>
                <Row
                    gutter={[16, 24]}
                    className="py-4 flex justify-center items-center"
                >
                    <Col lg={8} xs={24}>
                    <div className="flex justify-center">
                        <img src="../images/nextgen-mobile/stream.png" alt="" />
                    </div>
                    <div className="flex justify-center">
                        <p className="text-lg py-4">
                            5G Media Streaming
                        </p>
                    </div>
                    </Col>
                    <Col lg={8} xs={24}>
                    <div className="flex justify-center">
                        <img src="../images/nextgen-mobile/multicast.png" alt="" />
                    </div>
                    <div className="flex justify-center">
                        <p className="text-lg py-4">
                            5G Broadcast/Multicast with FeMBMS
                        </p>
                    </div>
                    </Col>
                    <Col lg={8} xs={24}>
                    <div className="flex justify-center">
                        <img src="../images/nextgen-mobile/dvb.png" alt="" />
                    </div>
                    <div className="flex justify-center">
                        <p className="text-lg py-4">
                            DVB-I integrated with 5G
                        </p>
                    </div>
                    </Col>
                </Row>
            </div>
        </div>
        <div className="flex nextgen-mobile-bottom py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <p className="text-lg  leading-loose align-justify">
                Media and entertainment ‘experiences’ enabled by 5G will generate up to $1.3 trillion (£0.9 trillion)
                in revenue by 2028, according to a new report commissioned by Intel and carried out by Ovum. This
                is almost half of the projected $3 trillion (£2.3 trillion) in wireless revenues overall. The report
                suggests that 2025 will represent a ‘tipping point’ for 5G in entertainment and media. By that time,
                the report forecasts that around 57 percent of wireless revenue globally will be driven by the
                capabilities of 5G networks and devices. By 2028, Intel and Ovum expect that number to rise to 80%.
            </p>
          </div>
        </div>

    </div>
    <Clientele />
    <MembershipSlider />
    {/* <TechnologyHeading text="Take the next step in NextGen Mobile technology." /> */}
    <ReachOut />
    </>
  );
};

export default NextGenMobile;
