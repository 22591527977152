import React from "react";
import "../../assets/style/services.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const Embedeed = () => {
  return (
    <>
      <div className="pb-8">
        <div className="flex items-center embedeed-section">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-2xl uppercase">
              Leading expertise in <br className="xs-hidden" />
              Embedded Technology
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center text-center embeded-system">
          <div className="container">
            <h4 className="uppercase text-3xl font-bold pb-4">
              EMBEDDED SYSTEMS
            </h4>
            <p className="text-lg leading-loose  align-justify">
              OptM offers embedded software design and development solutions across industries. 
              We help companies to accelerate the R&D cycle and drive new product development. 
              Our team of experts have rich experience and expertise across platforms to ensure 
              smooth product rollout, mitigating business risks, incorporating best practices to 
              safeguard IP, and complying with global embedded software & certifications standards. 
              Our services portfolio includes from product inception to field deployment.
            </p>
          </div>
        </div>
        <div className="what-we-offer">
          <div className="container">
            <h4 className="font-semibold text-2xl uppercase pb-4">
              What we offer
            </h4>
            <Row className="flex" gutter={[16, 24]}>
              <Col lg={8} xs={24} className="text-lg">
                <p className="pt-2">
                  {" "}
                  System architecture and partitioning with Hypervisor
                  integration.{" "}
                </p>
                <p className="pt-2">Board Design & Bring-up.</p>
                <p className="pt-2">Sub system integration and porting.</p>
                <p className="pt-2">Device driver development.</p>
                <p className="pt-2">Custom Kernel drivers.</p>
                <p className="pt-2">
                  Middleware & Application development (Assembly/C/C++).
                </p>
              </Col>
              <Col lg={8} xs={24} className="text-lg ">
                <p className="pt-2">
                  HMI for embedded devices – Qt, Android, JavaScript /React
                  JS/Angular etc.
                </p>
                <p className="pt-2">OS/Kernel modifications and patches.</p>
                <p className="pt-2">Open-source software (OSS) hardening.</p>
                <p className="pt-2">Embedded android development.</p>
                <p className="pt-2">Cross-compilation toolchains.</p>
              </Col>
              <Col lg={8} xs={24}>
                <img src="../images/embedded/ebd-offer.png" alt="" />
              </Col>
            </Row>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center platform-expertise">
          <div className="container">
            <h4 className="uppercase text-2xl font-bold pb-4">
              Our Platform Expertise
            </h4>
            <p className="text-lg  leading-loose align-justify">
              OptM applies its vast embedded firmware design knowledge to optimize your application fully. 
              We undertake feasibility studies for new embedded solutions, ensuring product viability through 
              performance tuning and enhancements across the product lifecycle. Be it for hard real-time 
              performance or making the most of minimal resources, our team has extensive hands-on experience 
              with common embedded platforms and operating systems, including:
            </p>
          </div>
        </div>
        <div className="">
          <div className="container">
            <Row gutter={[16, 24]}>
              <Col lg={8} xs={24}>
                <img src="../images/embedded/firmware.png" alt="" />
              </Col>
              <Col lg={6} xs={24}>
                <p className="text-lg">i368</p>
                <p className="text-lg">PPC</p>
                <p className="text-lg">ARM</p>
                <p className="text-lg">Embedded Linux</p>
              </Col>
              <Col lg={6} xs={24} className="lg:pl-8">
                <p className="text-lg">QNX</p>
                <p className="text-lg">VxWorks</p>
                <p className="text-lg">Bare Metal</p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="flex why-us  items-center py-8">
        <div className="container">
          <h4 className="text-2xl font-bold uppercase">Why Us</h4>
          <Row
            gutter={[16, 24]}
            className="py-4 flex justify-center items-center"
          >
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/embedded/product-design.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">Multiple Product Design</p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/embedded/extensive.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">Extensive Hands-on Experience</p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/embedded/microchip.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Framework or Non-Framework Application
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/embedded/firmware-design.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">Embedded Firmware Design</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading
        text="Take the next step in Embedded System."
        style={{ paddingLeft: "8rem" }}
      /> */}
      <ReachOut />
    </>
  );
};

export default Embedeed;
