import React from "react";
import "../../assets/style/services.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const Microservices = () => {
  return (
    <>
      <div className="flex items-center microservices-section">
        <div className="container">
          <h1 className="text-white font-bold lg:text-5xl text-xl uppercase">
            Transform your Business
            <br className="xs-hidden" /> for agility & scalability
          </h1>
        </div>
      </div>
      <div className="pt-8 lg:pb-8 pb-4 justify-center items-center lg:text-center embeded-system">
        <div className="container">
          <div className="">
            <h4 className="uppercase text-3xl font-bold text-center pb-4">
              Microservices
            </h4>
            <p className="text-lg align-justify">
              Designing and implementing a successful cloud strategy requires a
              service provider with experience in large-scale digital
              transformation. This transformation can be accelerated by
              microservices and its architecture uses granular, distributed
              services with well-defined APIs based on domain driven design. It
              promises to offer better scalability, improved agility,
              reliability and a reduced time to market. Enterprises that use
              microservices as a building block for their distributed enterprise
              systems can augment and aggregate existing functionality as a
              service.
            </p>
          </div>
        </div>
      </div>
      <div className="what-we-offer pb-8">
        <div className="container">
          <h4 className="font-semibold text-2xl  uppercase lg:py-8 py-4">
            Drive business transformation
          </h4>
          <p className="text-lg " style={{ borderBottom: "none" }}>
            Companies have been able to drive many different types of business
            transformation By leveraging microservices
          </p>
          <Row gutter={[16, 24]}>
            <Col lg={10}>
              <div className="">
                <p className="text-lg align-justify pt-2">
                  When enterprises decide to move from traditional monolithic
                  oriented architecture to cloud-based applications, development
                  for cloud-based systems can be accelerated with microservices
                </p>
                <p className="text-lg mob-bottom align-justify pt-2">
                  Microservices provide the flexibility to try out a new
                  technology stack on an individual service as needed.
                </p>

                <p className="text-lg mob-bottom align-justify pt-2">
                  We help enterprises visualize and plan the whole journey,
                  including the coexistence of legacy systems and microservices
                  during transition, CI & CD ecosystem, Quality assurance, API
                  documentation and versioning, discovery, security,
                  scalability, infrastructure, and team discipline aspects.
                </p>
              </div>
            </Col>
            <Col lg={10} offset={2} className="mob-left">
              <p className="text-lg  align-justify">
                Microservices have helped companies align business and IT
                functionality, improve business and technology agility, and
                enable incremental (agile) development and continuous software
                deployment
              </p>

              <p className="text-lg mob-bottom pb-4 align-justify pt-2">
                Solutions that require integrating business capabilities from
                disparate systems and programming models can be aided by a
                microservices approach
              </p>
              <p className="text-lg mob-bottom pt-2 align-justify">
                Microservices can help ensure a high level of availability for
                core functions
              </p>
              <p className="text-lg mob-bottom pt-2 align-justify pb-8">
                Our team of experts can design and develop microservices for
                agility, ease of scalability (including functional
                decomposition, data partitioning and horizontal scaling of
                individual services), efficient maintenance (decoupled),
                performance, and security.
              </p>
            </Col>
          </Row>
        </div>
      </div>
      {/* <div className="justify-center items-center lg:text-center embeded-system">
        <div className="container">
          <div className="lg:py-8">
            <p className="text-lg  lg:px-16 align-justify">
             
            </p>
          </div>
        </div>
      </div> */}
      <div className="flex why-us  items-center py-8">
        <div className="container">
          <h4 className="text-2xl font-bold uppercase">Why Us</h4>
          <Row
            gutter={[16, 24]}
            className="py-4 flex justify-center items-center"
          >
            <Col lg={8} xs={24}>
              <div className="flex justify-center">
                <img src="../images/microservices/robust.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Robust in-house frameworks to build custom Microservices
                </p>
              </div>
            </Col>
            <Col lg={8} xs={24}>
              <div className="flex justify-center">
                <img src="../images/microservices/dedicated.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Dedicated center of excellence for microservices &
                  containerization
                </p>
              </div>
            </Col>
            <Col lg={8} xs={24}>
              <div className="flex justify-center">
                <img src="../images/microservices/re-useable.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Re-usable assets and artifacts for faster implementation
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in Microservices." /> */}
      <ReachOut />
    </>
  );
};

export default Microservices;
