import React from "react";
import "../../assets/style/products.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import Navbar from "../header/Navbar";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const NextGenRadio = () => {
  return (
    <>
      <div className="pb-0">
        <div className="flex items-center nextgen-radio-banner">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-xl ">
              NEXTGEN RADIO  <br className="xs-hidden" />
              SOLUTIONS
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="text-3xl font-bold text-center pb-4">NEXTGEN RADIO</h4>
            <p className="text-lg  leading-loose align-justify">
                OptM is one of the proud member of Digital Radio Mondiale (DRM). DRM allows seamless transition
                to digital radio with the upgrade of existing transmitter infrastructure as well as with analogue-digital
                simulcast configurations. DRM offers exceptional Sound quality on AM & FM band and good potential
                for additional revenue for broadcasters from targeted advertisements, radio schooling etc.
            </p>
            <p className="text-lg  leading-loose align-justify">
                Transitioning to the cloud provides businesses new opportunities and operational muscles to unlock
                innovation across their organization including fundamentally reinventing their business models.
                With extensive industry and technology expertise, our team of professionals can work with you to
                strategically identify, plan and propel your business transformation with cloud enabled products
                and services including DRM.
            </p>
          </div>
        </div>

        <div className="flex why-us  items-center py-8">
            <div className="container">
                <Row gutter={[16, 24]}
                    className="py-4 flex justify-center items-center">
                    <Col lg={12} xs={24}>
                    <div className="flex justify-center">
                        <img src="../images/nextgen-radio/standalone-radio.png" alt="" />
                    </div>
                    <div className="flex justify-center">
                        <h4 className="text-xl font-bold uppercase pt-4">STANDALONE RADIO SOLUTIONS</h4>
                    </div>
                    <div className="flex justify-center ml-24 mr-20">
                            OptM is developing low cost standalone DRM receivers to aid higher penetration of 
                            NextGen Radio as part of “Make in India” program based on NXP platform.
                    </div>
                    </Col>
                    <Col lg={12} xs={24}>
                    <div className="flex justify-center">
                        <img src="../images/nextgen-radio/automotive-radio.png" alt="" />
                    </div>
                    <div className="flex justify-center">
                        <h4 className="text-xl font-bold uppercase pt-4">AUTOMOTIVE RADIO</h4>
                    </div>
                    <div className="flex justify-center ml-24 mr-20">
                            We are also exploring development of various
                            DRM based solutions for Car Radios and Infotainment
                            consoles with our partners including Fraunhofer, NXP and Dolby.
                    </div>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
    <Clientele />
    <MembershipSlider />
    {/* <TechnologyHeading text="Take the next step in NextGen Radio technology." /> */}
    <ReachOut />
    </>
  );
};

export default NextGenRadio;
