import React from "react";
import "../../assets/style/about.scss";
// import Navbar from "../header/Navbar";
import { Row, Col, Card } from "antd";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
const AboutUs = () => {
  return (
    <>
      <div className="flex items-center about-section">
        <div className="container">
          <h1 className="text-white font-bold lg:text-5xl text-2xl uppercase">
            About Us
          </h1>
        </div>
      </div>
      <div className="flex justify-center lg:text-center items-center">
        <div className="container py-12">
          <h4 className="lg:text-3xl text-xl text-primary py-2 font-bold text-center">
            WHO WE ARE ?
          </h4>
          <p className="text-lg lg:px-32 align-justify">
            OptM is a premier product development and professional services company 
            having headquarters in Bangalore and presence in Dover, DE US. 
            We are working on developing next generation products / solutions to 
            Transform Automotive & Media experience etc., 
            Our comprehensive portfolio of services coupled with state-of-the-art technology 
            building blocks, provides our customers with advantages of top-of-the-line technologies 
            to realize their time critical and high-performance product development and deployment 
            and together we realize a new class of next generation products & solutions to enable & drive growth.
          </p>
        </div>
      </div>
      {/* 
      <div
        className="flex flex-row justify-center items-center py-8 job-card"
        style={{ background: "#f7f7f7" }}
      >
        {" "}
        <div className="container ">
          <h4 className="lg:text-4xl text-xl text-primary font-bold">
            Our Mission
          </h4>
          <Row gutter={[16, 24]}>
            <Col lg={24} xs={24}>
              <p className="text-lg">
                We endeavor to inspire innovation, formulate and Implement
                tailor made solutions for our client / stakeholders and bring IT
                Solutions under one roof and we anticipate to do this by
                creating groundbreaking innovations, making our products and
                services sustainable, contributing to bring in a positive impact
                in the environment we live and work in
              </p>
            </Col>
          </Row>
        </div>
      </div> */}
      <div className="flex justify-center lg:text-center items-center py-12 bg-grey job-card">
        {" "}
        <div className="container">
          <Row gutter={[6, 24]}>
            <Col lg={6} xs={24} offset={1}>
              <div children="pb-8">
                <h4 className="lg:text-3xl text-xl text-primary font-bold pb-4">
                  VISION
                </h4>
                <p className="text-lg pb-8 align-justify">
                  To establish itself as a virtuous leader in developing world’s
                  best software, for the respective technology landscape that we
                  are working on.
                </p>
              </div>
            </Col>
            <Col lg={6} xs={24} offset={2}>
              <img
                src="../images/mission-and-vision.png"
                alt="Vision and Mission"
              />
            </Col>
            <Col lg={6} xs={24} offset={2}>
              <div>
                <h4 className="lg:text-3xl text-xl text-primary font-bold pb-4">
                  MISSION
                </h4>
                <p className="text-lg align-justify">
                  We endeavor to be the forefront in disruptive innovation to
                  provide world class services &amp; solutions to our customers
                  and bring sustainable IT Solutions under one roof to transform
                  business growth.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div
        className="flex justify-center items-center py-8"
        style={{ background: "#f7f7f7" }}
      >
        <div className="container leadership-thought">
          <h4 className="uppercase text-primary font-bold lg:text-3xl text-xl py-4 text-center pb-8">
            Thought Leadership
          </h4>
          <Row>
            <Col lg={7} xs={24} offset={1} className="mob-left">
              <Card
                className="mob-card"
                headStyle={{ borderRadius: "12px" }}
                bodyStyle={{ borderRadius: "0px" }}
                style={{ minHeight: "520px", borderRadius: "12px" }}
              >
                <div className="profile  flex justify-center">
                  <img
                    src="../images/about/umesh1.jpg"
                    alt=""
                    style={{
                      width: "175px",
                      height: "175px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="profile-details lg:text-center">
                  <h4 className="text-2xl text-primary pt-4 text-center">
                    Umesh Tallam
                  </h4>
                  <p className="text-xl text-center pb-4">
                    Chief Executive Officer
                  </p>
                  <p
                    className="text-lg lg:px-8 pb-4"
                    style={{ textAlign: "justify" }}
                  >
                    A thorough technocrat and mature business leader. His deep
                    insights into TV, media streaming and automotive & other
                    industries have enabled a strong foundation for several
                    successful ventures.
                  </p>
                </div>
              </Card>
            </Col>
            <Col lg={7} xs={24} offset={1} className="mob-left">
              <Card
                className="mob-card"
                bodyStyle={{ borderRadius: "0px" }}
                style={{ minHeight: "520px", borderRadius: "12px" }}
              >
                <div className="profile  flex justify-center">
                  <img
                    src="../images/about/atmananda.png"
                    alt=""
                    style={{
                      width: "175px",
                      height: "175px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="profile-details lg:text-center">
                  <h4 className="text-2xl text-primary pt-4 text-center">
                    Athmananda C P
                  </h4>
                  <p className="text-xl text-center pb-4">
                    Chief Technology Officer
                  </p>
                  <p
                    className="text-lg lg:px-8 pb-4 "
                    style={{ textAlign: "justify" }}
                  >
                    Deep rooted technologist with strong acumen of Embedded
                    Technology and couple of decades of industry experience in
                    Product and Solution development in automotive and consumer
                    electronics domain.
                  </p>
                </div>
              </Card>
            </Col>
            <Col lg={7} xs={24} offset={1} className="mob-left">
              <Card
                className="mob-card"
                bodyStyle={{ borderRadius: "0px" }}
                style={{ minHeight: "520px", borderRadius: "12px" }}
              >
                <div className="profile  flex justify-center">
                  <img
                    src="../images/about/lakshmi1.jpg"
                    alt=""
                    style={{
                      width: "175px",
                      height: "175px",
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <div className="profile-details lg:text-center">
                  <h4 className="text-2xl text-primary pt-4 text-center">
                    Hemantha Lakshmi
                  </h4>
                  <p className="text-xl text-center pb-4">Head of Operations</p>
                  <p
                    className="text-lg lg:px-8 pb-4 "
                    style={{ textAlign: "justify" }}
                  >
                    Expert in overseeing the day-to-day administrative and
                    operational functions of the business. She has excellent
                    leadership skills and ability to effectively manage, lead
                    and supervise the team.
                  </p>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
    </>
  );
};

export default AboutUs;
