import React from "react";
import "../../assets/style/services.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const Semiconductor = () => {
  return (
    <>
      <section>
        <div className="flex items-center semiconductor-section">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-xl uppercase">
              end-to-end services
              <br className="xs-hidden" /> for silicon realization
            </h1>
          </div>
        </div>
        <div className="py-8  justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <div className="">
              <h4 className="uppercase text-3xl font-bold text-center pb-4">
                SEMICONDUCTOR
              </h4>
              <p className="text-lg align-justify">
                The team offers its customers the ability to engage at any stage
                of the semiconductor process from ASIC design, verification, and
                physical design to all the way to post silicon validation.
              </p>
            </div>
          </div>
        </div>
        <div className="what-we-offer ">
          <div className="container">
            <Row gutter={[16, 24]} className="pb-4">
              <Col lg={10}>
                <div className="">
                  <p className="text-lg">
                    <span className="font-bold"> IP DESIGN:</span> <br />
                    We have extensive experience in developing IP’s for
                    high-speed interfaces & memory controllers.
                  </p>
                  <p className="text-lg mob-bottom pt-2">
                    <span className="font-bold">
                      SOC / SUBSYSTEM INTEGRATION:
                    </span>
                    <br />
                    We have expertise in integration, front-end tool flow setup
                    and analysis.
                  </p>
                </div>
              </Col>
              <Col lg={10} offset={2} className="mob-left">
                <p className="text-lg ">
                  <span className="font-bold"> TEST CHIP DEV:</span> <br /> We
                  have expertise in end to end test chip development for mixed
                  signal IP’s
                </p>

                <p className="text-lg pt-2">
                  <span className="font-bold"> IP MAINTENANCE & SUPPORT:</span>
                  <br />
                  Expertise in maintaining legacy IPs for adding new features,
                  post-silicon bug fixes, making process changes and supporting
                  SOC teams.
                </p>
              </Col>
            </Row>
            <hr />
          </div>
        </div>
      </section>
      <section id="fpga">
        <div className="what-we-offer">
          <div className="container">
            <h4 className="font-semibold lg:text-2xl text-xl uppercase lg:pt-8 pt-4 pb-4">
              ASIC/FPGA Capabilities
            </h4>

            <Row gutter={[16, 24]} className="pb-4">
              <Col lg={10}>
                <div className="">
                  <p className="text-lg">New designs</p>
                  <p className="text-lg pt-2">Die-size reduction</p>
                  <p className="text-lg pt-2">Silicon Validation Offering</p>
                </div>
              </Col>
              <Col lg={10} offset={2} className="mob-left">
                <p className="text-lg ">Cost-reduction</p>

                <p className="text-lg pt-2">Feature enhancement</p>
              </Col>
            </Row>
            <hr />
          </div>
        </div>
      </section>
      <section id="design-verification">
        <div className="what-we-offer ">
          <div className="container">
            <h4 className="font-semibold lg:text-2xl text-xl uppercase lg:pt-8 pt-4 pb-4">
              Verification Offering
            </h4>

            <Row gutter={[16, 24]} className="pb-4">
              <Col lg={10}>
                <div className="">
                  <p className="text-lg">
                    Module, Block & full Chip Verification
                  </p>
                  <p className="text-lg pt-2">Protocol expertise</p>
                  <p className="text-lg pt-2">STestbench development</p>
                </div>
              </Col>
              <Col lg={10} offset={2} className="mob-left">
                <p className="text-lg ">Verification IP development</p>

                <p className="text-lg pt-2">
                  SoC (C/SV/ASM based), Subsystem & Block/IP level verification
                </p>
              </Col>
            </Row>
            <hr />
          </div>
        </div>
        <div className="what-we-offer">
          <div className="container">
            <h4 className="font-semibold lg:text-2xl text-xl uppercase lg:pt-8 pt-4 pb-4">
              Silicon Validation Offering
            </h4>

            <Row gutter={[16, 24]} className="pb-4">
              <Col lg={10}>
                <div className="">
                  <p className="text-lg">Testchip development</p>
                  <p className="text-lg pt-2 ">
                    Reference Boards & Test boards development
                  </p>
                  <p className="text-lg pt-2 ">Layout</p>
                </div>
              </Col>
              <Col lg={10} offset={2} className="mob-left">
                <p className="text-lg ">IPC Certified Layout engineers</p>

                <p className="text-lg pt-2">Prototyping solutions</p>
              </Col>
            </Row>
            <hr />
          </div>
        </div>
        <div className="what-we-offer">
          <div className="container">
            <h4 className="font-semibold lg:text-2xl text-xl uppercase lg:pt-8 pt-4 pb-4">
              IP Validation Offering
            </h4>

            <Row gutter={[16, 24]} className="pb-4">
              <Col lg={10}>
                <div className="">
                  <p className="text-lg">Bench characterization and testing</p>
                  <p className="text-lg pt-2">Jitter profile for IPs</p>
                </div>
              </Col>
              <Col lg={10} offset={2} className="mob-left">
                <p className="text-lg ">
                  LDOs, BGRs, PORs and temperature sensors
                </p>
              </Col>
            </Row>
            <hr />
          </div>
        </div>
      </section>
      <section id="board-design">
        <div className="what-we-offer pb-8" id="board-design">
          <div className="container">
            <h4 className="font-semibold lg:text-2xl text-xl uppercase lg:pt-8 pt-4 pb-4">
              Hardware Design Services
            </h4>

            <Row gutter={[16, 24]}>
              <Col lg={10}>
                <div className="">
                  <p className="text-lg">
                    Development Platforms and System-on-Modules (SoM)
                  </p>
                  <p className="text-lg pt-2">
                    Embedded Processor based Designs
                  </p>
                  <p className="text-lg pt-2">
                    Power-optimized and Battery-operated Designs
                  </p>
                  <p className="text-lg pt-2">
                    Small-footprint hardware Designs
                  </p>
                </div>
              </Col>
              <Col lg={10} offset={2} className="mob-left">
                <p className="text-lg ">PCB Layout and Analysis</p>

                <p className="text-lg pt-2">Ruggedization</p>
                <p className="text-lg pt-2">
                  Multi-core and multi-processor embedded hardware design
                  services
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <div className="flex why-us  items-center py-8">
        <div className="container">
          <h4 className="text-2xl font-bold uppercase">Why Us</h4>
          <Row
            gutter={[16, 24]}
            className="py-4 flex justify-center items-center"
          >
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/silicon-engineering/fpga.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Quick prototyping services for multi-million ASIC into
                  multiple FPGAs
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/silicon-engineering/chip.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Expertise in end to end test chip development for mixed signal
                  IP's
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/silicon-engineering/scalable.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Reusable & Scalable verification environment development
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../images/silicon-engineering/reuseable.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Diverse portfolio of services including ASIC design,
                  verification, Physical design & Layout FPGA
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in Semiconductor." /> */}
      <ReachOut />
    </>
  );
};

export default Semiconductor;
