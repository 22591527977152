import React, { useState } from "react";
import Navbar from "../header/Navbar";
import ProductSlider from "./ProductSlider";
import "../../assets/style/home.scss";
//import "../../assets/style/home-popup.scss";
import Solution from "./Solution";
import Hero from "./Hero";
import ReachOut from "./ReachOut";
import Clientele from "./Clienttele";
import MembershipSlider from "./Membership";
// import TechnologyHeading from "./TechnologyHeading";
import ModalPopup from "../header/ModalPopup";

const Home = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // const showModal = () => {
  //   closeMobileMenu();
  //   setIsModalVisible(true);
  // };
  const handleClose = (e) => {
    setIsModalVisible(false);
  };
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <React.Fragment>
      <video autoPlay muted loop id="myVideo" style={{ zIndex: "0" }}>
        <source src="../video/home.mp4" type="video/mp4" />
      </video>

      <div className="content">
        <div className="video-text">
          <h1 className="lg:text-5xl text-xl uppercase font-bold w-full text-white mb-0 main-heading">
            {/* <p className="text-white"></p> */}
            Transcending <br /> Product and Digital <br />
            Transformation
          </h1>
          {/* <Button className="know-more-btn">
            <Link to="/ai-ml">Know more</Link>
          </Button> */}
        </div>
      </div>

      {/* <Hero /> */}
      <ProductSlider />
      <Solution />

      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in bleeding-edge technology." /> */}
      <ReachOut />
      <ModalPopup show={isModalVisible} onClose={handleClose} />
    </React.Fragment>
  );
};

export default Home;
