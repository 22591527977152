import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const MembershipSlider = () => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <>
      <div className="flex justify-center items-center py-8 clientele">
        <div className="container">
          <h4 className="text-2xl uppercase font-semibold pb-8">
            Memberships
          </h4>
          <Slider {...settings}>
            <div>
              <img src="../images/membership/drm.png" alt=""  style={{ width: "200px", height: "75px", paddingRight: "10px"}}/>
            </div>
            <div>
              <img src="../images/membership/a3sa.png" alt=""  style={{ width: "200px", height: "75px", paddingRight: "10px"}}/>
            </div>
            <div>
              <img src="../images/membership/tsdsi.png" alt=""  style={{ width: "150px", height: "60px", paddingRight: "10px", paddingTop: "20px"}}/>
            </div>
            <div>
              <img src="../images/membership/voice.png" alt=""  style={{ width: "200px", height: "75px", paddingRight: "10px"}}/>
            </div>
            {/* <div>
              <img src="../images/membership/5g-mag.png" alt="" style={{ width: "150px", height: "80px", paddingRight: "50px"}}/>
            </div> */}
          </Slider>
        </div>
      </div>
    </>
  );
};

export default MembershipSlider;
