import React from "react";
import "../../assets/style/products.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const AudioFocus = () => {
  return (
    <>
      <div className="pb-0">
        <div className="flex items-center nextgen-radio-banner">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-xl ">
              AUDIO FOCUS
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="text-3xl font-bold uppercase text-center pb-4">Personalized Audio Zones</h4>
            <p className="text-lg  leading-loose align-justify">
                Automakers are eager to cram more screens and more features into their models, 
                all to make your commute as enjoyable & effortless as possible. Personalized 
                Auto Zone solutions enables car makers to divide the space as zones and offer audio services.
            </p>
            <div className="flex justify-center">
                <img src="../images/audio-focus/audio-zone.png" alt="" />
            </div>
          </div>
        </div>

        <div className="sub-title justify-center items-center pt-12 pb-4">
          <div className="container">
            <Row className="flex" gutter={[16, 24]} style={{alignItems: 'center'}}>
              <Col lg={10} xs={24} className="text-lg">
                <p class="text-lg align-justify">
                    Personalised Audio Zone solution offers custom, personal audio 
                    experiences for everyone inside the car.
                </p>
                <p className="text-lg align-justify pt-1">
                    Unique Technology where there is no need for additional speakers 
                    neither planar nor directional speakers &  utilizes existing cabin speakers.
                </p >
                <p className="align-justify pt-1">
                    Audio zone  control is managed by our DSP filters and algorithm.
                </p>
                <p className="text-lg align-justify pt-1">
                    OptM delivered this solution on Analog devices platform and 
                    shall customize for other platforms depending on the customer need.
                </p>
              </Col>
              <Col lg={12} s={24} className="text-lg">
                <div className="flex justify-center pb-12">
                    <img src="../images/audio-focus/audio-zone-control.png" 
                        alt="" width="90%" className="mb-padding-top" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
    </div>
    <Clientele />
    <MembershipSlider />
    {/* <TechnologyHeading text="Take the next step in NextGen Radio technology." /> */}
    <ReachOut />
    </>
  );
};

export default AudioFocus;
