import React from "react";
import "../../assets/style/solution.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const DRMASS = () => {
  return (
    <>
      <div className="pb-8">
        <div className="flex items-center drmaas-banner">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-xl ">
              DIGITAL RADIO <br className="xs-hidden" /> AS A SERVICE
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="text-3xl font-bold text-center pb-4">DIGITAL RADIO AS A SERVICE</h4>
            <p className="text-lg  leading-loose align-justify">
                We have launched OptM DRM Accelerator programme focusing on developing both Software & Product
                solution for radio broadcasters, automobiles and standalone radio receivers as part of Startup India and Make
                In India. Our DRM Accelerator enables private FM broadcasters to move to Digital Radio as a Service (DRMaaS)
                offered in AWS Cloud; without any upfront capital investment.
            </p>
          </div>
        </div>
        <div className="sub-title justify-center items-center ">
          <div className="container">
            <Row className="flex" gutter={[16, 24]} style={{alignItems: 'center'}}>
              <Col lg={10} xs={24} className="text-lg">
                <h4 className="font-semibold text-2xl uppercase">
                    DRMAAS
                </h4>
                <p class="text-lg align-justify">
                    Launch Digital Radio Services via our managed services
                    for Private FM Broadcasters
                </p>
                <p className="text-lg align-justify pt-1">
                    Digital Radio Broadcasting without CAPEX investment.
                </p >
                <p className="align-justify pt-1">
                    Low cost Digital Radio Receivers.
                </p>
                <p className="text-lg align-justify pt-1">
                    Digital Radio for Car infotainment solutions.
                </p>
                <p className="text-lg align-justify pt-1">
                    Integration, middleware & Development.
                </p>
                <p className="text-lg align-justify pt-1">
                    Potential for additional revenue for broadcasters
                    from targeted advertisements, radio schooling etc.
                </p>
              </Col>
              <Col lg={12} s={24} className="text-lg">
                <div className="flex justify-center">
                    <img src="../images/drmaas/drmaas.png" 
                        alt="" width="100%" className="mb-padding-top pl-6" />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in digital radio technology." /> */}
      <ReachOut />
    </>
  );
};

export default DRMASS;
