import React from "react";
import "../../assets/style/services.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const Cloud = () => {
  return (
    <>
      <div className="flex items-center cloud-section">
        <div className="container">
          <h1 className="text-white font-bold lg:text-5xl text-2xl uppercase">
            Upgrade your Business <br />
            With cloud-based solutions
          </h1>
        </div>
      </div>
      <div className=" py-8 justify-center items-center lg:text-center  embeded-system">
        <div className="container">
          <div>
            <h4 className="uppercase text-3xl font-bold text-center pb-4">
              CLOUD
            </h4>
            <p className="text-lg  align-justify">
              Owing years of expertise in Digital Transformation, OptM offers
              professional consultancy services to help companies choose the
              right cloud strategy and guide them to decide on whether to
              leverage private or public cloud computing infrastructure or
              multi-cloud and hybrid environments and Dockerization.
            </p>
            <p className="text-lg align-justify pt-2">
              Customized cloud strategies have become the trend to help
              companies grow and scale up quickly as per the demand of the
              market. Our cloud experts will help you modernize your
              infrastructure and data warehouse, to protect your software data
              and build applications encouraging microservices architecture &
              blockchain.
            </p>
          </div>
        </div>
      </div>
      <div className="what-we-offer">
        <div className="container">
          <h4 className="font-semibold lg:text-2xl text-xl uppercase py-8 pb-4">
            Cloud Migration
          </h4>
          <Row gutter={[16, 24]} className="pb-4">
            <Col lg={10}>
              <div className="">
                <p
                  className="text-lg align-justify"
                  style={{ borderBottom: "0px" }}
                >
                  We specialize in building all types of cloud-based solutions:
                  cloud-native apps, hybrid apps, custom software as a service
                  (SaaS), and platform as a service (PaaS) component.
                </p>
                <p className="text-lg align-justify">
                  We also offer system/app inventory, code analysis, risk
                  assessments & security assessment as well as elaborate a
                  thorough migration plan to enable with zero migration
                  downtime. We offer a well-defined set of tools that can give
                  you the flexibility to choose the method that best applies to
                  your needs.
                </p>
              </div>
            </Col>
            <Col lg={10} offset={2}>
              <p className="text-lg ">
                <span className="text-dark-blue">Minimize risk</span> and meet
                compliance mandates with security and resiliency
              </p>

              <p className="text-lg pt-2">
                <span className="text-dark-blue">Manage hybrid </span>
                multicloud environments with enhanced availability,
                recoverability, governance
              </p>

              <p className="text-lg pt-2">
                <span className="text-dark-blue">Reduce shadow IT</span> and
                cloud spend by increasing visibility
              </p>
            </Col>
          </Row>
          <hr className="py-4" />
        </div>
      </div>
      <div className="what-we-offer">
        <div className="container">
          <h4 className="font-semibold lg:text-2xl text-xl uppercase pb-4">
            Cloud Optimization
          </h4>
          <Row gutter={[16, 24]}>
            <Col lg={10}>
              <div className="ai-offerings">
                <p className="text-lg align-justify">
                  We can help you evaluate your cloud environment, report on
                  critical security gaps, recommend remediation steps, and
                  re-architect for maximum efficiency.
                </p>
              </div>
            </Col>
            <Col lg={10} offset={2}>
              <div className="cloud-migration-feature">
                <p className="text-lg pb-4">
                  <span className="text-dark-blue">Optimizing </span> your Cloud
                  Architecture
                </p>
                <p className="text-lg pb-4 pt-2">
                  <span className="text-dark-blue">Improving </span>
                  Availability and Achieving 100% Uptime
                </p>
                <p className="text-lg pb-4  pt-2">
                  <span className="text-dark-blue"> Reducing </span>
                  Cloud Costs and Maximizing ROI
                </p>
                <p className="text-lg pb-4 pt-2">
                  <span className="text-dark-blue"> Assessing </span>
                  Security & Compliance
                </p>
              </div>
            </Col>
          </Row>
          <hr />
        </div>
      </div>
      <div className="what-we-offer">
        <div className="container">
          <h4 className="font-semibold lg:text-2xl text-xl uppercase pt-8 pb-4">
            DevOps Consulting
          </h4>
          <Row gutter={[16, 24]}>
            <Col lg={10}>
              <div className="ai-offerings">
                <p className="text-lg align-justify">
                  We offer end-to-end DevOps consulting services based on the
                  modern methodologies designed to deliver high-quality software
                  faster. Our DevOps experts will help you align your operations
                  to achieve the increased frequency of software releases and
                  improve its reliability.
                </p>
              </div>
            </Col>
            <Col lg={10} offset={2}>
              <div className="cloud-migration-feature">
                <p className="text-lg pb-4">
                  <span className="text-dark-blue">Workflow </span> assessment -
                  DevOps
                </p>
                <p className="text-lg pb-4 pt-2">
                  <span className="text-dark-blue"> Process </span>
                  transformation and automation - DevOps
                </p>
                <p className="text-lg pb-4 pt-2">
                  <span className="text-dark-blue"> Continuous </span>
                  integration and deployment pipeline
                </p>
                <p className="text-lg pb-4 pt-2">
                  <span className="text-dark-blue align-justify">
                    {" "}
                    Microservices{" "}
                  </span>
                  With years of experience, OptM has been at the forefront in
                  the deployment of secure IoT solutions with cloud computing at
                  the core. OptM leverages the power of Azure IoT Hub, Amazon
                  IoT Core, and Google Cloud IoT services to develop solutions
                  that enable fast and secure communication between IoT
                  applications and the devices they manage.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="what-we-offer pb-8">
        <div className="container">
          <h4 className="font-semibold lg:text-2xl text-xl uppercase py-8">
            IoT Cloud Based Solutions
          </h4>
          <Row gutter={[16, 24]}>
            <Col lg={10}>
              <div className="flex ai-offerings">
                <img src="../images/cloud/migration.png" width="60" alt="" />

                <div className="pl-4">
                  <h5 className="text-xl ">Cloud Migration</h5>
                  <p className="text-lg align-justify">
                    The process begins with an infrastructure audit, where we
                    discover the strengths and weaknesses of the system, analyze
                    project requirements, choose a cloud service provider and
                    prepare a consistent plan for safe migration to the cloud.
                  </p>
                </div>
              </div>
              <div className="flex ai-offerings">
                <img src="../images/cloud/hybrid.png" width="60" alt="" />

                <div className="pl-4">
                  <h5 className="text-xl ">Hybrid Cloud Development</h5>
                  <p className="text-lg align-justify">
                    Run your workloads smoothly with our advanced computing
                    environments and hybrid cloud app development services.We
                    have solid expertise in connecting the company’s on-premises
                    private cloud application services with public ones into a
                    flexible and single infrastructure.
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={10} offset={2}>
              <div className="flex ai-offerings">
                <img src="../images/cloud/optimization.png" width="60" alt="" />

                <div className="pl-4">
                  <h5 className="text-xl ">Cloud Optimization</h5>
                  <p className="text-lg align-justify">
                    We provide cost effective feasibility study and support in
                    developing prototypes or POCs to test the viability of ML
                    models to address the requirements.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in Cloud based solution." /> */}
      <ReachOut />
    </>
  );
};

export default Cloud;
