import React from "react";
import { Row, Col, Button, Form, Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../assets/style/contact.scss";
import API from "../../config";

const { TextArea } = Input;

const ContactForm = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleSubmit = (e) => {
    fetch(API.CONTACT_US, {
      crossDomain: true,
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        message: message,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error status " + response.status);
        }
        return response.json();
      })
      .then((response) => {
        form.resetFields();
        toast.success("Thank you for contacting us. We will revert back shortly", {
          className: "error-toast",
          draggable: true,
          enableHtml: true,
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        form.resetFields();
        toast.error(
          "Form Submit Failed",
          {
            className: "error-toast",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          },
          err
        );
      });
  };

  const [form] = Form.useForm();

  return (
    <>
      <div className="flex lg:block md:flex-row flex-col justify-center itmes-center lg:pt-8 lg:py-0 py-8">
        <div className="container mx-auto">
          <div className="lg:flex flex-col items-center justify-center lg:pb-16 ">
            <Form
              form={form}
              onFinish={handleSubmit}
              layout="vertical"
              hideRequiredMark
              className="contactform lg:w-2/3 w-full"
            >
              <Row gutter={[24]}>
                <Col lg={12} xs={24}>
                  <Form.Item
                    label="NAME"
                    name="name"
                    rules={[
                      {
                        whitespace: false,
                        required: true,
                        message: "Enter your Name",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      placeholder="Type your  Name here"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                  <Form.Item
                    label="PHONE NO"
                    name="phone"
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        max: "11",
                        message: "Please enter your phone number!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your Phone here"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      name="phone"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[24]}>
                <Col lg={24} xs={24}>
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Enter Valid Email!",
                      },
                      { required: true, message: "Enter your Email" },
                    ]}
                  >
                    <Input
                      type="email"
                      name="email"
                      value={email}
                      placeholder="Enter your Email Id here"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 24]}>
                <Col lg={24} xs={24}>
                  <Form.Item
                    label="YOUR MESSAGE"
                    name="message"
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: "Enter your message",
                      },
                    ]}
                  >
                    <TextArea
                      rows={5}
                      placeholder="Share your message here"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[16, 24]} style={{ marginBottom: "0px" }}>
                <Col lg={24} xs={24} style={{ paddingBottom: "0px" }}>
                  <Form.Item>
                    <Button className="w-full send-btn" htmlType="submit">
                      SEND MESSAGE
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default ContactForm;
