import React from "react";
import "../../assets/style/services.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const DigitalSignalProcessing = () => {
  return (
    <>
      <div className="pb-8">
        <div className="flex items-center dsp-section">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-2xl uppercase">
              Leading experts in <br className="xs-hidden" />
              DIGITAL SIGNAL PROCESSING
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="uppercase text-3xl font-bold text-center pb-4">
              DIGITAL SIGNAL PROCESSING
            </h4>
            <p className="text-lg  leading-loose align-justify">
              Digital signal processing / DSP design projects are becoming
              increasingly challenging in the electronics industry and many
              organizations seek help from experienced DSP consultants. Greater
              system complexity, as well as strict performance targets, often
              makes it difficult for companies to bring electronic products to
              market quickly and efficiently.
            </p>
            <p className="text-lg  leading-loose align-justify pt-2">
              At OptM, with extensive experience working with leading companies,
              we will ensure that your planned concepts are quickly translated
              into successful products. Our expert DSP team have trained
              engineers who work to design and develop real-time solutions
              putting the best design methodologies to use and develop designs
              that deliver higher productivity and save your time.
            </p>
          </div>
        </div>
        <div className="what-we-offer pb-8">
          <div className="container">
            <h4 className="font-semibold text-2xl uppercase pb-4">
              What we offer
            </h4>
            <Row className="justify-center items-center" gutter={[16, 24]}>
              <Col lg={10} xs={24} offset={2} className="text-lg">
                <p>Algorithm development</p>
                <p className="pt-2">Architecture definition</p>
                <p className="pt-2">Digital Signal Processing design review</p>
                <p className="pt-2">
                  Co-processing, device, and system partitioning
                </p>
              </Col>
              <Col lg={10} xs={24} offset={2} className="text-lg ">
                <p>FPGA implementation</p>
                <p className="pt-2">MATLAB and Simulink simulation</p>
                <p className="pt-2">Verification and debugging</p>
                <p className="pt-2">
                  Optimization for cost, performance, and efficiency
                </p>
              </Col>
            </Row>
          </div>
        </div>

        <div className="flex why-us  items-center py-8">
          <div className="container">
            <h4 className="text-2xl font-bold uppercase">Why Us</h4>
            <Row
              gutter={[16, 24]}
              className="py-4 flex justify-center items-center"
            >
              <Col lg={12} xs={24}>
                <div className="flex justify-center">
                  <img src="../images/dsp/fpgp.png" alt="" />
                </div>
                <div className="flex justify-center">
                  <p className="text-lg py-4">
                    Specialized in FPGA / Digital Signal Processing design
                    projects, algorithm development and high-level design
                    methodologies
                  </p>
                </div>
              </Col>
              <Col lg={12} xs={24}>
                <div className="flex justify-center">
                  <img src="../images/dsp/multi-core.png" alt="" />
                </div>
                <div className="flex justify-center">
                  <p className="text-lg py-4">
                    Multi-core processor, multi- processor, FPGA based hardware
                    designs
                  </p>
                </div>
              </Col>
              <Col lg={12} xs={24}>
                <div className="flex justify-center">
                  <img src="../images/dsp/cost.png" alt="" />
                </div>
                <div className="flex justify-center">
                  <p className="text-lg py-4">
                    Optimization for cost, performance and efficiency
                  </p>
                </div>
              </Col>
              <Col lg={12} xs={24}>
                <div className="flex justify-center">
                  <img src="../images/dsp/micro-chip.png" alt="" />
                </div>
                <div className="flex justify-center">
                  <p className="text-lg py-4">Turnkey FPGA solutions</p>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in DSP technology." /> */}
      <ReachOut />
    </>
  );
};

export default DigitalSignalProcessing;
