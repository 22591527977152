import React, { useState, useEffect } from "react";
import { Row, Col, Button, Select, Form, Input, Modal, Upload } from "antd";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../config";
import { UploadOutlined } from "@ant-design/icons";
const { Option } = Select;
const ApplyModal = (props) => {
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(props.show);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [experience, setExperience] = React.useState(0);
  const [location, setLocation] = React.useState("");
  const [resume, setResume] = React.useState([]);

  const fileProps = {
    multiple: false,
    accept:
      ".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.txt",
    beforeUpload: (file, fileList) => {
      setResume(fileList);
      // const supportedFileTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingm', 'text/plain'];
      // if (supportedFileTypes.indexOf(file.type) === -1) {
      //   message.error(`${file.name} is not a recommened format`);
      // }
      // return supportedFileTypes.indexOf(file.type) >=0 ? true : Upload.LIST_IGNORE;
      return false;
    },
    onChange: (info) => {
      console.log("filelist", info.fileList);
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  useEffect(() => {
    setIsModalVisible(props.show);
  }, [props.show]);

  const handleCancel = (e) => {
    props.onClose(e);
    setIsModalVisible(false);
  };
  const handleSubmit = (e) => {
    const formData = new FormData();

    for (const file of resume) {
      formData.append("resume[]", file, file.name);
    }

    formData.append("name", name);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("gender", gender);
    formData.append("location", location);
    formData.append("experience", experience);
    formData.append("jobCode", props.additionalInfo.jobCode);
    formData.append("jobTitle", props.additionalInfo.jobTitle);

    props.onClose(e); // This is a callback handler to reset the model status to flase

    fetch(API.APPLY_JOB, {
      crossDomain: true,
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error status " + response.status);
        }
        return response.json();
      })
      .then((response) => {
        form.resetFields();
        setIsModalVisible(false);
        toast.success("Your career request submitted successfully.", {
          className: "error-toast",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        form.resetFields();
        toast.error(
          "Form Submit Failed",
          {
            className: "error-toast",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          },
          err
        );
      });
  };

  return (
    <>
      <Modal
        visible={isModalVisible}
        footer={false}
        onCancel={handleCancel}
        className="job-form"
        centered
      >
        <div>
          <h4 className="text-2xl text-white font-bold text-center pb-4">
            Job Application Form
          </h4>
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            className="job-application-form"
          >
            <Row gutter={[16, 24]}>
              <Col lg={12} xs={24}>
                <Form.Item
                  name="name"
                  label="Full Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your full name!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    type="text"
                    name="name"
                    value={name}
                    placeholder="Type your full name"
                    onChange={(e) => setName(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  name="email"
                  label="E-mail"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Enter your Email Id"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  name="phone"
                  label="Phone Number"
                  rules={[
                    {
                      required: true,
                      message: "Please input your phone number!",
                    },
                  ]}
                >
                  <Input
                    name="phone"
                    type="text"
                    style={{ width: "100%" }}
                    placeholder="Enter your phone number"
                    onChange={(e) => setPhone(e.target.value)}
                    value={phone}
                  />
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[{ required: true, message: "Please select gender!" }]}
                >
                  <Select
                    name="gender"
                    onChange={(val) => setGender(val)}
                    placeholder="Select your gender"
                  >
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                    <Option value="Other">Other</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  name="location"
                  label="Current Location"
                  rules={[
                    {
                      required: true,
                      message: "Please select current location!",
                    },
                  ]}
                >
                  <Select
                    name="location"
                    onChange={(val) => setLocation(val)}
                    placeholder="Select your current location"
                  >
                    <Option value="Bangalore">Bangalore</Option>
                    <Option value="Delhi">Delhi</Option>
                    <Option value="Mumbai">Mumbai</Option>
                    <Option value="Hydrabad">Hyderabad</Option>
                    <Option value="Chennai">Chennai</Option>
                    <Option value="Pune">Pune</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col lg={12} xs={24}>
                <Form.Item
                  name="experience"
                  label="Year of Experience"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your experience!",
                      whitespace: true,
                    },
                  ]}
                >
                  <Input
                    name="experience"
                    type="number"
                    style={{ width: "100%" }}
                    placeholder="Enter your experience"
                    onChange={(e) => setExperience(e.target.value)}
                    value={experience}
                  />
                </Form.Item>
              </Col>
              <Col lg={24} xs={24}>
                <Form.Item
                  name="resume"
                  label="Upload Resume"
                  valuePropName="fileList[]"
                  rules={[
                    {
                      required: true,
                      message: "Please upload your profile",
                    },
                  ]}
                >
                  <Upload {...fileProps} listType="file">
                    <Button
                      icon={<UploadOutlined />}
                      className="upload-btn"
                    ></Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col lg={24} xs={24}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="job-submit"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default ApplyModal;
