import React, { useState, useEffect } from "react";
import { Menu, Dropdown, Row, Col, Modal, Form, Input, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API from "../../config";
const { TextArea } = Input;
const ModalPopup = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(props.show);

  // ------------------
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [form] = Form.useForm();
  // -----------------

  useEffect(() => {
    setIsModalVisible(props.show);
  }, [props.show]);

  const handleCancel = (e) => {
    props.onClose(e);
    setIsModalVisible(false);
  };
  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const handleSubmit = (e) => {
    fetch(API.CONTACT_US, {
      crossDomain: true,
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        message: message,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error status " + response.status);
        }
        return response.json();
      })
      .then((response) => {
        form.resetFields();
        setIsModalVisible(false);
        toast.success("Form Submitted Successfully", {
          className: "error-toast",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        form.resetFields();
        toast.error(
          "Form Submit Failed",
          {
            className: "error-toast",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          },
          err
        );
      });
  };
  return (
    <>
      <div>
        <Modal
          visible={isModalVisible}
          footer={false}
          onCancel={handleCancel}
          className="letstalk home-popup"
          centered
        >
          <div>
            <Row>
              <Col lg={12} xs={24}>
                <div className="flex justify-center items-center p-8">
                  <h4 className="lg:text-4xl text-2xl lg:pl-8 pt-8 mb-0">
                    Have a project? <span>Let's talk.</span>
                  </h4>
                </div>
                <div className="talk-popup">
                  <ul className=" leading-loose lg:pl-16 pl-8 text-lg">
                    <div className="flex ">
                      <li className="flex">
                        <div
                          className="font-bg xs-hidden"
                          style={{ width: "42px" }}
                        >
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            style={{
                              fontSize: "21px",
                              color: "#fff",
                              marginTop: "12px",
                            }}
                          />
                        </div>

                        <div>
                          OptM Media Solutions Private Ltd
                          <br /> 63, Ram Mandir Road, Basavanagudi,
                          <br /> Bangalore 560004
                          <br />
                        </div>
                      </li>
                    </div>
                    {/* <div className="flex ">
                  <img
                    src="../../../images/telephone.png"
                    alt=""
                    style={{ margin: "11px 15px", height: "20px" }}
                  />
                  <li>+1234567890</li>
                </div> */}
                    <div className="flex ">
                      <li className="flex pt-4 pb-4">
                        <div className="font-bg xs-hidden">
                          <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            style={{
                              fontSize: "21px",
                              color: "#fff",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                        <strong className="font-bold">talk2us</strong>
                        @optmsol.com
                      </li>
                    </div>
                    <div className="flex ">
                      <li className="flex pt-4">
                        <div className="font-bg xs-hidden">
                          <FontAwesomeIcon
                            icon={faPhoneAlt}
                            style={{
                              fontSize: "21px",
                              color: "#fff",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                        <div>
                          <strong>India : </strong> +91-9886722627 <br />
                        </div>
                      </li>
                    </div>
                  </ul>

                  {/* <p className="flex justify-center items-center text-xl leading-loose pb-2">
                <img
                  src="../images/icons/message.png"
                  alt="email"
                  style={{
                    width: "40px",
                    height: "35px",
                    paddingRight: "10px",
                  }}
                />
                talk2us@optmsol.com
              </p>

              <p
                className="flex justify-center items-center text-xl leading-loose pb-2"
                style={{ marginLeft: "-30px" }}
              >
                <img
                  src="../images/icons/phone.png"
                  alt="phone"
                  style={{
                    width: "40px",
                    height: "35px",
                    paddingRight: "10px",
                  }}
                />
                +91-988 672 2627
              </p>

              <p className="flex justify-center items-center text-xl map-icon leading-loose pb-2">
                <img
                  src="../images/icons/map.png"
                  alt="address"
                  style={{
                    width: "40px",
                    height: "35px",
                    paddingRight: "10px",
                  }}
                />
                63, Ram Mandir Road, Basavanagudi, Bangalore - 560004
              </p> */}
                </div>
              </Col>
              <Col
                lg={12}
                xs={24}
                className="popup-bg lg:p-16 p-8 flex justify-center items-center"
              >
                <Form
                  form={form}
                  className="reach-out-form"
                  name="connectform"
                  layout="vertical"
                  onFinish={handleSubmit}
                  hideRequiredMark
                >
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: "Please enter your name!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      placeholder="Type your  Name here"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Enter valid Email id!",
                      },
                      {
                        required: true,
                        message: "Please enter your email!",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      name="email"
                      value={email}
                      placeholder="Enter your Email here"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        min: 10,
                        max: 10,
                        message: "Please input your phone number!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your Phone here"
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      name="phone"
                    />
                  </Form.Item>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: "Enter your message!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={5}
                      placeholder="Share your requirement here"
                      name="message"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="w-full rounded  send-btn"
                      style={{ height: "45px" }}
                      htmlType="submit"
                    >
                      SEND MESSAGE
                    </Button>
                  </Form.Item>
                </Form>
              </Col>
            </Row>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ModalPopup;
