import React from "react";
import "../../assets/style/solution.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
// import TechnologyHeading from "../home/TechnologyHeading";

const SolutionAutomotive = () => {
  return (
    <>
      <div>
        <div className="flex items-center justify-start automative-header-title">
          <div className="container">
            <h1 className="text-white uppercase lg:text-4xl text-2xl ">
              NextGen Connected Vehicles <br className="xs-hidden" />
              And Infotainment Solutions
            </h1>
          </div>
        </div>
        <div className="flex w-full justify-center items-center lg:py-16 py-8 automative-contents">
          <div className="container lg:flex ">
            <div className="lg:w-1/2 w-full">
              <h4 className="text-3xl font-semibold uppercase pb-4">
                AUTOMOTIVE
              </h4>

              <h4 className="text-xl">
                <strong>Electric Vehicle Instrument Cluster </strong>
              </h4>
              <p
                className="text-lg align-justify"
                style={{ lineHeight: "30px" }}
              >
                Developed based on i.MX6 & Qt and Combining a full-color
                TFT-display. EV Instrument Cluster has an outstanding HMI, the
                feature-rich, user-friendly display makes motorcycle riding a
                fascinating and safe experience with enhanced convenience. All
                vehicle related information’s are displayed with exceptional
                clarity and the interaction with the system is simple and
                intuitive, assisting the rider to stay well informed at all
                times.
              </p>
            </div>
            <div className="lg:w-1/2 w-full  lg:pl-8 lg:pt-0 pt-8">
              <img
                src="../images/automotive/img3.png"
                alt=""
                width="100%"
                className="mb-padding-top"
              />
            </div>
          </div>
        </div>
        <div className="container lg:flex pb-16 ">
          <div className="lg:w-1/2 w-full lg:pb-0 pb-8">
            <img
              src="../images/automotive/img4.png"
              alt=""
              className="mb-padding-bottom"
              style={{ width: "567px" }}
            />
          </div>
          <div className="lg:w-1/2 w-full">
            <p
              className="text-lg align-justify "
              style={{ lineHeight: "30px" }}
            >
              The device supports several gauges which includes, speedometer,
              battery status, charging status, time to complete charging,
              odometer & maps etc. as well as various indicators for system
              malfunctions and warnings. It also supports GSM, GPS and GPRS
              support for cloud connectivity, internet connectivity and
              navigation. The connectivity interfaces, additionally allows
              connected functions and services to enhance the overall riding
              experience.
            </p>
            <p
              className="text-lg align-justify pt-2"
              style={{ lineHeight: "30px" }}
            >
              Instrument cluster solution is highly scalable and customizable
              with BOM optimization, ready to deploy.
            </p>
            <p
              className="text-lg align-justify pt-2"
              style={{ lineHeight: "30px" }}
            >
              Off the shelf reference designs & SDK are also available to enable
              you to build your proprietary instrument cluster.
            </p>
          </div>
        </div>
        {/* <div className=" what-we-offer pb-8">
          <div className="container">
            <h4 className="font-semibold text-2xl uppercase pb-4">
              What we offer
            </h4>
            <Row className="flex" gutter={[16, 24]}>
              <Col lg={12} xs={24} className="text-lg">
                <p>
                  <span>ADAS & Autonomous Vehicles:</span> Algorithm
                  optimization, Porting, Testing, Sensor experience (Camera,
                  RADAR, pDAR)
                </p>
                <p className="pt-2">
                  <span>Connected Vehicles:</span> Sensor integration, V2V/V2X,
                  remote diagnostics, cloud-based analytics.
                </p>
                <p className="pt-2">
                  <span>Electric Vehicles Solutions:</span> Charging station
                  (design, hardware, software, and testing)
                </p>
                <p className="pt-2">
                  <span>Telematics:</span> Hardware & Firmware development, GPS,
                  Navigation, Fleet management (e.g., vehicle tracking,
                  surveillance)
                </p>
              </Col>
              <Col
                lg={12}
                xs={24}
                className="text-lg solution-items"
                style={{ paddingLeft: "45px" }}
              >
                <p>
                  <span> Integrated Cockpit:</span> Hardware design, ECU
                  software, HMI & application, platform development,
                  verification & validation
                </p>
                <p className="pt-2">
                  <span>Multimedia:</span> Audio/Video Codecs, Processing
                  algorithms, Interfaces & driver development
                </p>
                <p className="pt-2">
                  <span>AUTOSAR:</span> Stack (BSW, RTE, COM) configuration &
                  integration, MCAL development, BSP & Device driver, AUTOSAR
                  migration / upgradation
                </p>
                <p className="pt-2">
                  <span>Digital Transformation:</span> Cloud based Integration
                  and deployments in-vehicle apps and external mobile apps with
                  cloud automotive services & Supply chain solutions.
                </p>
              </Col>
            </Row>
          </div>
        </div> */}
      </div>
      {/* <div className="flex why-us  items-center py-8">
        <div className="container">
          <h4 className="text-2xl font-bold uppercase">Why Us</h4>
          <Row
            gutter={[16, 24]}
            className="py-4 flex justify-center items-center"
          >
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../../images/automotive/industry.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  80+ years of Industry experience in automotive technologies
                  from bus protocols and ECUs to applications and infotainment
                  solutions
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../../images/automotive/expertise.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Expertise in multiple Industry vertices and leading platforms
                  to explore new opportunities
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../../images/automotive/rocket.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Shortening your time from initial concept, planning and
                  implementation to delivery using our best practices
                </p>
              </div>
            </Col>
            <Col lg={12} xs={24}>
              <div className="flex justify-center">
                <img src="../../images/automotive/microchip.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Expertise across Automotive operating platform such as
                  Android, Tizen, Linux, Windows embedded & QNX
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div> */}

      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in Automotive technology." /> */}
      <ReachOut />
    </>
  );
};

export default SolutionAutomotive;
