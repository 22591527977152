const jobData = [
  {
    jobId: "OPTMCR001",
    jobName: "Sr Android Developer",
    experience: "8+ years",
    qualification: "BE/B.Tech",
    responsibility: [
      "Experience in designing and building Android applications ",
      "Good experience of Android architecture design patterns like MVVM/MVP, restful web services and libraries to access them ",
      "Must have used Android Jetpack components, Android SDK tools for performance analysis",
      "Should have very good debugging and bug fixing skills ",
      "Good knowledge of Android SDK ",
      "Strong knowledge of Java and Kotlin ",
      "Good in Data structure and Problem solving",
    ],
    employeeStatus: "Full time Employment",
    jobPosting: "August 30 2021",
  },
  {
    jobId: "OPTMCR002",
    jobName: "Dev Test Engineer",
    experience: "12+ years",
    qualification: "BS or MS in Computer Science ",
    responsibility: [
      "Deep understanding of software testing concepts and best practices",
      "Proven ability to lead, plan, design, implement, and execute effective functional, system, and automation tests for complex, highly available enterprise solutions and advanced tools",
      "Experience leading an Agile/Scrum development process and solid experience with Jira and Confluence development methodologies",
      "Strong team player comfortable working with geographically distributed and cross functional teams and willingness to mentor less experienced team members",
      "Superior troubleshooting, communications, and documentation skills",
      "Experience validating clinical integrations middleware solutions preferred",
      "Proactive in the face of ambiguous requirements; unafraid to explore new ideas and technologies",
      "Resourceful and able to solve complex problems through practical use of technology and a thorough understanding of product architecture",
      "Independent self-starter; able to complete projects on time with minimal guidance",
      "Superior analytical, verbal, and written communication skills ",
    ],

    employeeStatus: "Full time Employment",
    jobPosting: "August 25 2021",
  },
  {
    jobId: "OPTMCR003",
    jobName: "Qt Engineer",
    experience: "3-5 years",
    qualification: "B.E / B. Tech / M.E / M. Tech / M.S / MCA ",
    responsibility: [
      "Experience in HMI Application Development in Linux Environment ",
      "Application Programming based on C++ and Qt/QML ",
      "Strong in C++ and OOPS ",
      "Strong Analytical and problem solving skills ",
      "C++ Qt frame work user interface desktop application ",
      "Extensive hands on experience in Desktop application development using Qt technology Experience with GUI implementation and debugging desktop applications using Qt ",
      "Experience in real-time communications software development and related build environments, with  focus on mission critical, low latency multithreaded applications ",
      "Experience designing, developing and debugging networked client/server ",
      "Applications Experienced with source/version control software, including Git",
      "Strong proficiency in C++, with fair knowledge of the language specification  ",
      "Thorough knowledge of the standard library, STL containers, Data Structures and algorithms",
    ],

    employeeStatus: "Full time Employment",
    jobPosting: "August 25 2021",
  },
  {
    jobId: "OPTMCR004",
    jobName: "Wi-Fi Developer",
    experience: "5-7+ years",
    qualification: "BE / B.Tech (CSE), ME/M.Tech. (ECE/EEE/IT) ",
    responsibility: [
      "Must have 5 to 7+ years of development work experience in Wi-Fi drivers integrations and development",
      "Design, develop and test high-performance network and wireless solutions on a various SoCs on OpenWRT platforms",
      "In-depth understanding and experience of working in Linux kernel/drivers, C/C++ ",
      "Experience of working with various wireless drivers and chipsets (BCM/ MTK/ QCA/ Marvell etc) ",
      "Strong understanding of 802.11 standards, Easymesh specs.",
      "Good problem-solving & debugging skills",
      "Good understanding MIPS, & ARM architectures",
      "Good understanding on OpenWRT framework and architecture ",
    ],

    employeeStatus: "Full time Employment",
    jobPosting: "August 25 2021",
  },
  {
    jobId: "OPTMCR005",
    jobName: "DevOps Developer",
    experience: "3-10 years",
    qualification: "B.E / B. Tech / M.E / M. Tech / M.S / MCA ",

    requiredSkills: [
      "Experience in designing and implementing complex application and systems solutions",
      "Mandatory; solid experience with working on UNIX/Linux platforms.",
      `Strong architectural background in Public/Private/Hybrid Cloud platforms like Amazon Web Services, MS Azure, Google Cloud Platform, Openstack etc.`,
      `Experience in DevOps tools like JIRA, Rally, Confluence, Jenkins, Git, SVN, Maven, Nexus, Docker, Ansible, Chef, Puppet and Kubernetes etc.`,
      "Ability to write code in at least one language (Go, Rust, Python, Perl, BASH, Java, JavaScript)",
      "Experience with automated deployment and release management (CI/CD, Jenkins, Artifact management system, Ansible, Puppet or an equivalent)",
      "Good understanding of offerings like Iaas, PaaS, SaaS etc. relevant design experience is an added advantage",
      "Experience with automating monitors and system performance reporting using ELK, Logstash, Kafka, and Prometheus, TICK, Grafana etc.",
      "Familiar with security concerns-> networking concerts (DNS, two factor authentication, SSL, VPN, VPC) will be added advantage.",
      "Added Advantage if worked on IoT device validation for key areas like NB-IoT protocol, FOTA (Firmware over the Air), Device Management, LWM2M, MQTT.",
    ],
    responsibility: [
      "Managing large scale production environments (50+ VMs, multiple services) on the cloud (AWS preferable).",
      "In production: deploy, support and monitor systems on the cloud (2+ years)",
      "Develop and maintain scripts to monitor the environment and to automate tasks",
      "Partner with the engineering team to ensure that the applications are designed with operability in mind",
      "Available 24X7 on need basis, if there are any second-tier escalations.",
      "Improve monitoring, alerting and resilience of the system.",
    ],

    employeeStatus: "Full time Employment",
    jobPosting: "August 25 2021",
  },
  {
    jobId: "OPTMCR006",
    jobName: "Software Developer",
    experience: "12+ years",
    qualification: "BS or MS in Computer Science ",
    responsibility: [
      "Deep understanding of software testing concepts and best practices",
      "Proven ability to lead, plan, design, implement, and execute effective functional, system, and automation tests for complex, highly available enterprise solutions and advanced tools",
      "Experience leading an Agile/Scrum development process and solid experience with Jira and Confluence development methodologies",
      "Strong team player comfortable working with geographically distributed and cross functional teams and willingness to mentor less experienced team members",
      "Superior troubleshooting, communications, and documentation skills",
      "Experience validating clinical integrations middleware solutions preferred",
      "Proactive in the face of ambiguous requirements; unafraid to explore new ideas and technologies",
      "Resourceful and able to solve complex problems through practical use of technology and a thorough understanding of product architecture",
      "Independent self-starter; able to complete projects on time with minimal guidance",
      "Superior analytical, verbal, and written communication skills ",
    ],

    employeeStatus: "Full time Employment",
    jobPosting: "August 25 2021",
  },
];

export default jobData;
