import React from "react";
import "../../assets/style/solution.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";

const HealthCare = () => {
  return (
    <>
      <div>
        <div className="flex items-center justify-start healthcare-header-title">
          <div className="container">
            <h1 className="text-white uppercase lg:text-4xl text-2xl">
              Transforming <br className="xs-hidden" />
              the future of healthcare
            </h1>
          </div>
        </div>
        <div className="py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <div>
              <h4 className="uppercase text-3xl font-bold text-center pb-4">
                HEALTHCARE
              </h4>
              <p className="text-lg  align-justify">
                Access and affordability to care and healthcare and to enhance
                the quality of the care provided is a theme that all governments
                in the world are concerned about. Despite the complex,
                competitive, and highly regulated world of healthcare, the
                healthcare industry is evolving and is moving to
                digitally-enabled, customer-centered, connected healthcare
                models. But this progression comes with its inherent challenges.
              </p>
              <p className="text-lg  align-justify">
                Leveraging Artificial Intelligence results in higher degree of
                automation, productivity, and standardization, but also extends
                to innovative usage of quantitative data to support better and
                personalized diagnostics, therapies and drug development.
              </p>

              <p className="text-lg pt-4  align-justify">
                OptM with rich domain expertise and capabilities help medical
                device OEMs  
                <ul
                  style={{
                    listStyleType: "square",
                    paddingLeft: "50px",
                    lineHeight: "30px",
                  }}
                >
                  <li>Address industry challenges.</li>
                  <li>Accelerate time to market and Optimize costs</li>
                  <li>
                    Simplify the clinical communication & workflow to improve
                    the lives of healthcare professionals, patients, and
                    families
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>

        <div className="flex w-full justify-center items-center bg-grey lg:py-16 py-8 automative-contents">
          <div className="container lg:flex ">
            <div className="lg:w-1/2 w-full">
              <h4 className="text-2xl font-semibold uppercase pb-4">
                OUR CORE EXPERTISE
              </h4>
              <p className="lg:text-xl text-lg pb-2 align-justify">
                <ul
                  style={{
                    listStyleType: "square",
                    paddingLeft: "20px",
                    lineHeight: "30px",
                    color: "#1e73ba",
                  }}
                >
                  <li>
                    Medical standards for data storage and communication
                    <ul
                      style={{
                        listStyleType: "circle",
                        paddingLeft: "30px",
                        lineHeight: "27px",
                        color: "#202020",
                      }}
                    >
                      <li>DICOM and HL7 FHIR</li>
                    </ul>
                  </li>
                  <li>
                    Medical coding standards for clinical documentation &
                    reporting
                    <ul
                      style={{
                        listStyleType: "circle",
                        paddingLeft: "30px",
                        lineHeight: "27px",
                        color: "#202020",
                      }}
                    >
                      <li>SNOMED CT</li>
                      <li>ICD 10</li>
                      <li>LOINC</li>
                    </ul>
                  </li>
                  <li>
                    Enterprise platform and workflow management
                    <ul
                      style={{
                        listStyleType: "circle",
                        paddingLeft: "30px",
                        lineHeight: "27px",
                        color: "#202020",
                      }}
                    >
                      <li>EMR</li>
                      <li>HIS</li>
                      <li>Telemedicine & PACS</li>
                    </ul>
                  </li>
                  <li>
                    AI in healthcare
                    <ul
                      style={{
                        listStyleType: "circle",
                        paddingLeft: "30px",
                        lineHeight: "27px",
                        color: "#202020",
                      }}
                    >
                      <li>Radiology</li>
                      <li>Medical devices</li>
                      <li>Telemedicine</li>
                    </ul>
                  </li>
                  <li> Medical data privacy and security complaince</li>
                </ul>
              </p>
            </div>
            <div className="lg:w-1/2 w-full  lg:pl-8">
              <img
                src="images/healthcare_expertise.jpeg"
                alt=""
                width="100%"
                style={{ maxHeight: "400px" }}
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center lg:text-center items-center py-12 job-card">
          {" "}
          <div className="container">
            <Row gutter={[6, 24]}>
              <Col lg={11} xs={24}>
                <div className="flex">
                  {" "}
                  <img
                    src="../images/health-check.png"
                    style={{ height: "56px", paddingRight: "22px" }}
                  />
                  <p className="text-lg lg:pb-8 align-justify">
                    We work with global medical device leaders providing
                    end-to-end product development solutions through concept
                    design, new product development, value engineering,
                    sustaining engineering and V&V.
                  </p>
                </div>
              </Col>

              <Col lg={11} xs={24} offset={2}>
                <div className="flex">
                  <img
                    src="../images/online-appointment.png"
                    style={{ height: "56px", paddingRight: "22px" }}
                  />
                  <p className="text-lg align-justify">
                    Over the years, we have developed smarter products for
                    global medical clients, from the design board to the shop
                    floor and cloud landscape. The result is both operational
                    excellence and more connected, predictive and personalized
                    care delivery.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        {/* <div className=" py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <div>
              <p className="text-lg align-justify">
              We work with global medical device leaders providing end-to-end product development 
              solutions through concept design, new product development, value engineering, 
              sustaining engineering and V&V.
              </p>
              <p className="text-lg align-justify">
              Over the years, we have developed smarter products for global medical clients, 
              from the design board to the shop floor and cloud landscape. The result is 
              both operational excellence and more connected, predictive and personalized care delivery.
              </p>
            </div>
          </div>
        </div> */}
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in healthcare technology." /> */}
      <ReachOut />
    </>
  );
};

export default HealthCare;
