import React from "react";
import { Button, Input, Form, Row, Col, Card } from "antd";
import API from "../../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
const { TextArea } = Input;
const ReachOut = () => {
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  const handleSubmit = (e) => {
    fetch(API.CONTACT_US, {
      crossDomain: true,
      method: "POST",
      headers: {
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        message: message,
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error status " + response.status);
        }
        return response.json();
      })
      .then((response) => {
        form.resetFields();
        toast.success("Form Submitted Successfully", {
          className: "error-toast",
          draggable: true,
          position: toast.POSITION.TOP_CENTER,
        });
      })
      .catch((err) => {
        console.log(err);
        form.resetFields();
        toast.error(
          "Form Submit Failed",
          {
            className: "error-toast",
            draggable: true,
            position: toast.POSITION.TOP_CENTER,
          },
          err
        );
      });
  };

  const [form] = Form.useForm();

  return (
    <>
      <div className="flex flex-col flex-row justify-center items-center py-16 reach-out-bg">
        <div className="container">
          <Row gutter={[16, 24]}>
            <Col lg={12} xs={24}>
              {/* <h4 className="uppercase text-primary  lg:text-2xl text-xl leading-loose">
                or give us a call
              </h4> */}
              <h4 className="uppercase text-white lg:text-2xl text-xl pb-4">
                optm media solutions
              </h4>

              <h5 className="text-xl leading-loose flex pb-2">
                <div className="font-bg xs-hidden">
                  <FontAwesomeIcon
                    icon={faEnvelopeOpen}
                    style={{
                      fontSize: "21px",
                      color: "#fff",
                      marginTop: "10px",
                    }}
                  />
                </div>
                <span className="text-primary-light">
                  <a className="underline" href="mailto:talk2us@optmsol.com">
                    {" "}
                    info@optmsol.com
                  </a>
                </span>
              </h5>

              <h5 className="text-xl leading-loose flex text-primary-light">
                <div className="flex ">
                  <li className="flex pt-4">
                    <div className="font-bg xs-hidden">
                      <FontAwesomeIcon
                        icon={faPhoneAlt}
                        style={{
                          fontSize: "21px",
                          color: "#fff",
                          marginTop: "10px",
                        }}
                      />
                    </div>
                    <div>
                      INDIA : +91-9886722627 <br />
                      USA : +1-6194882137
                    </div>
                  </li>
                </div>
              </h5>

              <p className="text-xl text-light-black pt-4 mob-justify">
                Give us a call or drop by anytime, we endeavour to answer all
                enquiries within 24 hours on business days.
              </p>
            </Col>
            <Col lg={9} xs={24} offset={3}>
              <Card className="reachout-card">
                <h4 className="uppercase text-primary  text-2xl pb-4">
                  Get in touch
                </h4>
                <Form
                  className="reach-out-form"
                  name="enqueryForm"
                  layout="vertical"
                  form={form}
                  onFinish={handleSubmit}
                  hideRequiredMark
                  style={{ width: "325px" }}
                >
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: "Please enter your name!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your Name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      name="name"
                    />
                  </Form.Item>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Enter valid Email id!",
                      },
                      {
                        required: true,
                        message: "Please enter your email!",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Enter your Email "
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      name="email"
                    />
                  </Form.Item>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        required: true,
                        min: 10,
                        max: 10,
                        message: "Please enter your phone number!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      placeholder="Enter your Phone "
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      name="phone"
                    />
                  </Form.Item>
                  <Form.Item
                    name="message"
                    rules={[
                      {
                        whitespace: true,
                        required: true,
                        message: "Enter your message!",
                      },
                    ]}
                  >
                    <TextArea
                      rows={5}
                      placeholder="Enter your requirement "
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      name="message"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      className="w-full rounded  send-btn"
                      style={{ height: "48px" }}
                      htmlType="submit"
                    >
                      SEND MESSAGE
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default ReachOut;
