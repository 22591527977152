import React from "react";
import "../../assets/style/services.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const Android = () => {
  return (
    <>
      <div className="pb-8">
        <div className="flex items-center android-section">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-2xl uppercase">
              End-to-End <br className="xs-hidden" />
              Android Experience
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="uppercase text-4xl font-bold pb-4">ANDROID</h4>
            <p className="text-lg  leading-loose align-justify">
              Our team of experts with decades of advanced android development and customization 
              experience have deeply understood Android technologies. We assist customers in 
              accelerating their product innovation and development to drive great value to your 
              business strategy & product development. We offer the following professional services 
              with our deep insight into C, C++, Java, and Java Native Interface (JNI) technologies.
            </p>
            <p className="text-lg  leading-loose align-justify pt-2 "></p>
          </div>
        </div>
        <div className="what-we-offer">
          <div className="container">
            <h4 className="font-semibold text-2xl uppercase pb-4">
              New Product Development
            </h4>
            <Row className="flex mb-8" gutter={[16, 24]}>
              <Col lg={6} xs={24}>
                <img
                  src="../images/android/android_mobile.png"
                  alt=""
                  style={{ height: "315px" }}
                />
              </Col>
              <Col lg={8} xs={24} className="text-lg mt-8">
                <p className="">
                  Bringing up of android on OEM custom hardware
                </p>
                <p className="pt-2">Linux BSP customization for android</p>
                <p className="pt-2">
                  Android core and HAL customization/ enhancements.
                </p>
                <p className="pt-2">Linux Kernel + Android modules.</p>
              </Col>
              <Col lg={8} xs={24} offset={1} className="text-lg lg:mt-8 ">
                <p>
                  Android runtime Dalvik VM core libraries in native space
                  android HAL.
                </p>
                <p className="pt-2">Productization.</p>
                <p className="pt-2">Device management (OTA support).</p>
                <p className="pt-2">Google certification.</p>
              </Col>
            </Row>
          </div>
        </div>

        <div className="py-8 upgrade-maintanence what-we-offer">
          <div className="container">
            <h4 className="uppercase font-bold text-2xl pb-8">
              Upgrade and Maintenance
            </h4>
            <Row gutter={[16, 24]} className="pl-0">
              <Col lg={6} xs={24}>
                <img
                  src="../images/android/kernel.png"
                  alt=""
                  style={{ width: "68%" }}
                />
              </Col>
              <Col lg={10} xs={24}>
                <p className="text-lg ">
                  Impact analysis of features on OEM devices due to changes in
                  new version of Android.
                </p>
                <p className="text-lg pt-2">
                  Bringing up of new version of Android on OEM custom hardware.
                </p>
                <p className="text-lg pt-2">
                  Migration to new version of Kernel as per Google mandate.
                </p>
                <p className="text-lg pt-2">
                  Operator Pre-certification and certification.
                </p>
              </Col>
              <Col lg={7} xs={24} offset={1}>
                <p className="text-lg">
                  Customer defect analysis and resolution.
                </p>
                <p className="text-lg pt-2">Security Maintenance Release</p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <div className="flex py-2 justify-center items-center embeded-system">
        <div className="container">
          <h4 className="uppercase text-2xl font-bold pb-4">
            Android App Development Services
          </h4>
          <p className="text-lg  leading-loose align-justify">
            Our in-depth knowledge of the Android architecture, middleware components, HIDL, etc., 
            provides best-in-class Android app development services and builds solutions to transform 
            your business.
          </p>
          <p className="text-lg  leading-loose align-justify pt-2 "></p>
        </div>
      </div>
      <div className="what-we-offer py-8">
        <div className="container">
          <h4 className="font-semibold text-2xl uppercase pb-4">
            Our diversified Android app development services
          </h4>
          <Row className="flex" gutter={[16, 24]}>
            <Col lg={9} xs={24}>
              <img
                src="../images/android/optm_android_tv.png"
                alt=""
                style={{ width: "100%" }}
              />
            </Col>
            <Col lg={7} xs={24} className="text-lg mt-16">
              <p className="">Android app UI/UX design.</p>
              <p className="pt-2">Custom Android application development.</p>
              <p className="pt-2">Kotlin app development.</p>
              <p className="pt-2">Flutter app development.</p>
            </Col>
            <Col lg={7} xs={24} offset={1} className="text-lg mt-16">
              <p className="">Migrating seamlessly to the Android platform.</p>
              <p className="pt-2">Android app consultation.</p>
              <p className="pt-2">App maintenance and support.</p>
            </Col>
          </Row>
        </div>
      </div>
      <div className="flex why-us  items-center py-8">
        <div className="container">
          <h4 className="text-2xl font-bold uppercase">Why Us</h4>
          <Row
            gutter={[16, 24]}
            className="py-4 flex justify-center items-center"
          >
            <Col lg={8} xs={24}>
              <div className="flex justify-center">
                <img src="../images/android/sdk.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">Android SDK & Framework</p>
              </div>
            </Col>
            <Col lg={8} xs={24}>
              <div className="flex justify-center">
                <img src="../images/android/ota.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">Device Management (OTA Support)</p>
              </div>
            </Col>
            <Col lg={8} xs={24}>
              <div className="flex justify-center">
                <img src="../images/android/customer-support.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">
                  Customer Defect Analysis & Resolution
                </p>
              </div>
            </Col>
          </Row>
          <Row className="pt-8">
            <Col lg={4} xs={24}></Col>
            <Col lg={8} xs={24}>
              <div className="flex justify-center">
                <img src="../images/android/cycle.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">Security Maintenance Release</p>
              </div>
            </Col>
            <Col lg={8} xs={24}>
              <div className="flex justify-center">
                <img src="../images/android/infotainment.png" alt="" />
              </div>
              <div className="flex justify-center">
                <p className="text-lg py-4">Infotainment</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in  Android." /> */}
      <ReachOut />
    </>
  );
};

export default Android;
