import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons/lib";
import MobileMenu from "./MobileMenu";
import CloseMenu from "./CloseMenu";
import { NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { Menu, Dropdown, Row, Col, Modal, Form, Input, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import "../../assets/style/responsive.scss";
import API from "../../config";
import "../../assets/style/navbar.scss";
import ModalPopup from "./ModalPopup";
// import { Button } from "react-bootstrap";
const { SubMenu } = Menu;

const { TextArea } = Input;

const products = (
  <Menu style={{ display: "flex" }}>
    <Menu.Item key="0" style={{ textAlign: "center" }}>
      <NavLink to="/nextgen-tv">
        <img
          src="../images/atsc.png"
          alt="5G BROADCAST"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0px 7px 0px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          NEXTGEN TV ATSC 3.O
        </span>
      </NavLink>
    </Menu.Item>

    {/* <Menu.Item key="0" style={{ textAlign: "center" }}>
      <NavLink to="/nextgen-radio">
        <img
          src="../images/nextgen-radio.png"
          alt="NEXTGEN RADIO DRM"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0px 7px 0px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          NEXTGEN RADIO DRM
        </span>
      </NavLink>
    </Menu.Item>

    <Menu.Item key="0" style={{ textAlign: "center" }}>
      <NavLink to="/nextgen-mobile">
        <img
          src="../images/nextgen-mobile.png"
          alt="NEXTGEN MOBILE 5G"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0px 7px 0px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          NEXTGEN MOBILE 5G
        </span>
      </NavLink>
    </Menu.Item> */}

    <Menu.Item key="1" style={{ textAlign: "center" }}>
      <NavLink to="/ev-cluster">
        <img
          src="../images/ev-cluster.png"
          alt="EV CLUSTER"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0px 7px 0px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          EV CLUSTER
        </span>
      </NavLink>
    </Menu.Item>

    <Menu.Item key="1" style={{ textAlign: "center" }}>
      <NavLink to="/audio-focus">
        <img
          src="../images/radio-focus.png"
          alt="AUDIO FOCUS"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0px 7px 0px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          AUDIO FOCUS
        </span>
      </NavLink>
    </Menu.Item>
    
  </Menu>
);

const menu = (
  <Menu style={{ display: "flex" }}>
    <Menu.Item key="0" style={{ textAlign: "center" }}>
      <NavLink to="/baas">
        <img
          src="../images/broadcast.png"
          alt="5G BROADCAST"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0px 7px 0px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          BROADCAST AS A SERVICE
        </span>
      </NavLink>
    </Menu.Item>

    <Menu.Item key="1" style={{ textAlign: "center" }}>
      <NavLink to="/drmaas">
        <img
          src="../images/digital-radio.png"
          alt="AUTOMOTIVE"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0px 7px 0px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          DIGITAL RADIO AS SERVICE
        </span>
      </NavLink>
    </Menu.Item>
    {/* <Menu.Item key="1" style={{ textAlign: "center" }}>
      <NavLink to="/solution-automotive">
        <img
          src="../images/automotive.png"
          alt="AUTOMOTIVE"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0px 7px 0px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          AUTOMOTIVE
        </span>
      </NavLink>
    </Menu.Item> */}
  </Menu>
);

const industryMenu = (
  <Menu style={{ display: "flex" }}>
    <Menu.Item key="3" style={{ textAlign: "center" }}>
      <NavLink to="/media-boardcast" className="uppercase">
        <img
          src="../images/media-broadcast.png"
          alt="media"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0 10px 6px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          Media & Broadcast
        </span>
      </NavLink>
    </Menu.Item>

    <Menu.Item key="2" style={{ textAlign: "center" }}>
      <NavLink to="/automotive">
        <img
          src="../images/automotive.png"
          alt="AUTOMOTIVE"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0 10px 6px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          AUTOMOTIVE
        </span>
      </NavLink>
    </Menu.Item>

    <Menu.Item key="1" style={{ textAlign: "center" }}>
      <NavLink to="/healthcare">
        <img
          src="../images/healthcare.png"
          alt="HEALTHCARE"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0 10px 6px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          HEALTHCARE
        </span>
      </NavLink>
    </Menu.Item>

    <Menu.Item key="0" style={{ textAlign: "center" }}>
      <NavLink to="/defense" className="uppercase">
        <img
          src="../images/defensee-lectronics.png"
          alt="ATS3.0"
          style={{
            height: "100px",
            width: "156px",
            margin: "10px 0 10px 6px",
            borderRadius: "8px",
          }}
        />
        <span className="drop-item" style={{ color: "#202020" }}>
          Defense Electronics
        </span>
      </NavLink>
    </Menu.Item>
  </Menu>
);
const servicemenu = (
  <Menu className="service-menu-link" style={{ display: "flex" }}>
    <Menu.Item key="2">
      <span>
        <NavLink to="/embeded">
          <img
            src="../images/devices.png"
            alt="device-engineering"
            style={{
              height: "100px",
              width: "160px",
              margin: "10px 0 16px 6px",
              borderRadius: "8px",
            }}
          />
          <span
            className="drop-item"
            style={{ paddingLeft: "12px", color: "#8F8F8F" }}
          >
            DEVICE ENGINEERING
          </span>
        </NavLink>
      </span>
      <ul className="uppercase ml-4  drop-submenu">
        <NavLink to="/embeded">
          <li>Embedded systems </li>
        </NavLink>
        <NavLink to="/android">
          <li>Android </li>
        </NavLink>
        <NavLink to="/qt">
          <li className="capitalize">Qt </li>
        </NavLink>
        <NavLink to="/digital-signal-processing">
          <li>DSP</li>
        </NavLink>
      </ul>
    </Menu.Item>

    <Menu.Item key="3" className="service-dropdown">
      <span>
        <NavLink to="/ai-ml">
          <img
            src="../images/digitalengineering.png"
            alt="AUTOMATIVE"
            style={{
              height: "100px",
              width: "160px",
              margin: "10px 0 16px 6px",
              borderRadius: "8px",
            }}
          />
          <span
            className="drop-item"
            style={{ paddingLeft: "12px", color: "#8F8F8F" }}
          >
            DIGITAL ENGINEERING
          </span>
        </NavLink>
      </span>
      <ul className="uppercase ml-4  drop-submenu">
        <NavLink to="/ai-ml">
          <li>AI & ML </li>
        </NavLink>
        <NavLink to="/bigdata">
          <li>Big data & Analytics </li>
        </NavLink>
        <NavLink to="/cloud">
          <li>Cloud</li>
        </NavLink>
        <NavLink to="/microservices">
          <li>Microservices</li>
        </NavLink>
      </ul>
    </Menu.Item>

    <Menu.Item key="4">
      <span>
        <NavLink to="/semiconductor">
          <img
            src="../images/Silicon Engineering@2x.png"
            alt="AUTOMOTIVE"
            style={{
              height: "100px",
              width: "160px",
              margin: "10px 0 16px 6px",
              borderRadius: "8px",
            }}
          />
          <span
            className="drop-item"
            style={{ paddingLeft: "12px", color: "#8F8F8F" }}
          >
            SILICON ENGINEERING
          </span>
        </NavLink>
      </span>
      <ul className="uppercase ml-4  drop-submenu">
        <li>
          <Link
            activeClass="active"
            to="/semiconductor"
            spy={false}
            smooth={true}
          >
            <NavLink to="/semiconductor">SEMICONDUCTOR</NavLink>
          </Link>{" "}
        </li>
        <li>
          <Link
            activeClass="active"
            to="fpga"
            spy={false}
            smooth={true}
            offset={-70}
          >
            <NavLink to="/semiconductor#fpga">
              ASIC/FPGA Design & Development
            </NavLink>
          </Link>{" "}
        </li>

        <li>
          <Link
            activeClass="active"
            to="design-verification"
            spy={false}
            smooth={true}
            offset={-70}
          >
            <NavLink to="/semiconductor#design-verification">
              Design Verification & Validation
            </NavLink>
          </Link>{" "}
        </li>

        <li>
          {" "}
          <Link
            activeClass="active"
            to="board-design"
            spy={false}
            smooth={true}
            offset={-70}
          >
            <NavLink to="/semiconductor#board-design">Board Designs</NavLink>
          </Link>
        </li>
      </ul>
    </Menu.Item>
  </Menu>
);

const Navbar = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  // ------------------
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [form] = Form.useForm();
  // -----------------

  const showModal = () => {
    closeMobileMenu();
    setIsModalVisible(true);
  };
  const handleClose = (e) => {
    setIsModalVisible(false);
  };

  const [click, setClick] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  const showButton = () => {
    // if (window.innerWidth <= 960) {
    //   setButton(false);
    // } else {
    //   setButton(true);
    // }
  };
  useEffect(() => {
    showButton();
    // if (window.innerWidth <= 567 && Window.location.pathname === "/") {
    //   setNavbar(true);
    // } else
    // console.log("hiii");
    console.log(window.innerWidth);
    if (window.location.pathname === "/" && window.innerWidth >= 750) {
      setNavbar(false);
      document.getElementById("whitelogo").src =
        "../images/optm_white_logo.svg";
    } else {
      setNavbar(true);
      document.getElementById("whitelogo").src =
        "../images/optm_logo_color.svg";
    }
  }, []);
  window.addEventListener("resize", showButton);
  const changeBackground = () => {
    if (window.scrollY >= 80 && window.location.pathname === "/") {
      setNavbar(true);
      document.getElementById("whitelogo").src =
        "../images/optm_logo_color.svg";
    } else {
      if (window.location.pathname === "/" && window.innerWidth >= 750) {
        setNavbar(false);
        document.getElementById("whitelogo").src =
          "../images/optm_white_logo.svg";
      } else {
        setNavbar(true);
        document.getElementById("whitelogo").src =
          "../images/optm_logo_color.svg";
      }
    }
  };
  window.addEventListener("scroll", changeBackground);

  return (
    <>
      <IconContext.Provider value={{ color: "#fff" }}>
        <nav className={navbar ? "navbar" : "navbar-active"}>
          <div
            className="navbar-container container"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <NavLink
              to="/"
              className="navbar-logo -mt-4 px-4 lg:mt-4 web-logo"
              onClick={closeMobileMenu}
            >
              <img src="../images/optm_white_logo.svg" alt="" id="whitelogo" />
              {/* <img
                src="../images/logo-color.svg"
                alt=""
                id="colorlogo"
                style={{ display: "none" }}
              /> */}
            </NavLink>
            <div className="menu-icon" onClick={handleClick}>
              {click ? (
                <CloseMenu className="close-icon font-normal" />
              ) : (
                <MobileMenu />
              )}
            </div>
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item web-ssub-menu">
                <Dropdown
                  overlay={products}
                  trigger={["hover"]}
                  placement="bottomCenter"
                >
                  <NavLink
                    to=""
                    className="nav-link"
                    style={{ color: "#fff" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    Products
                  </NavLink>
                </Dropdown>
              </li>
              <li
                className="nav-item mobile-active mobile-sub-menu"
                style={{ padding: "0px 15px !important" }}
              >
                <Menu
                  style={{ width: 313, paddingLeft: "34px !important" }}
                  // defaultSelectedKeys={["1"]}
                  // defaultOpenKeys={["sub2"]}
                  mode="inline"
                  className="mobile-about-submenu"
                >
                  <SubMenu
                    key="sub2"
                    title="PRODUCTS"
                    style={{ paddingLeft: "34px" }}
                  >
                    <Menu.Item key="5">
                      <NavLink
                        className="nav-link"
                        to="/nextgen-tv"
                        activeClassName={"nav-link--active"}
                        onClick={closeMobileMenu}
                        style={{ marginLeft: "-17px" }}
                      >
                        NEXTGEN TV ATSC 3.0
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item key="6">
                      <NavLink
                        className="nav-link"
                        to="/nextgen-radio"
                        activeClassName={"nav-link--active"}
                        onClick={closeMobileMenu}
                        style={{ marginLeft: "-15px" }}
                      >
                        NEXTGEN RADIO DRM
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="6">
                      <NavLink
                        className="nav-link"
                        to="/nextgen-mobile"
                        activeClassName={"nav-link--active"}
                        onClick={closeMobileMenu}
                        style={{ marginLeft: "-15px" }}
                      >
                        NEXTGEN MOBILE 5G
                      </NavLink>
                    </Menu.Item> */}
                    <Menu.Item key="6">
                      <NavLink
                        className="nav-link"
                        to="/ev-cluster"
                        activeClassName={"nav-link--active"}
                        onClick={closeMobileMenu}
                        style={{ marginLeft: "-15px" }}
                      >
                        EV CLUSTER
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="6">
                      <NavLink
                        className="nav-link"
                        to="/audio-focus"
                        activeClassName={"nav-link--active"}
                        onClick={closeMobileMenu}
                        style={{ marginLeft: "-15px" }}
                      >
                        AUDIO FOCUS
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              </li>
              <li className="nav-item web-ssub-menu">
                <Dropdown
                  overlay={menu}
                  trigger={["hover"]}
                  placement="bottomCenter"
                >
                  <NavLink
                    to=""
                    className="nav-link"
                    style={{ color: "#fff" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    Solutions
                  </NavLink>
                </Dropdown>
              </li>
              <li
                className="nav-item mobile-active mobile-sub-menu"
                style={{ padding: "0px 15px !important" }}
              >
                <Menu
                  style={{ width: 313, paddingLeft: "34px !important" }}
                  // defaultSelectedKeys={["1"]}
                  // defaultOpenKeys={["sub2"]}
                  mode="inline"
                  className="mobile-about-submenu"
                >
                  <SubMenu
                    key="sub2"
                    title="SOLUTIONS"
                    style={{ paddingLeft: "34px" }}
                  >
                    <Menu.Item key="5">
                      <NavLink
                        className="nav-link"
                        to="/atsc"
                        activeClassName={"nav-link--active"}
                        onClick={closeMobileMenu}
                        style={{ marginLeft: "-17px" }}
                      >
                        BROADCAST A AS SERVICE
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="6">
                      <NavLink
                        className="nav-link"
                        to="/solution-automotive"
                        activeClassName={"nav-link--active"}
                        onClick={closeMobileMenu}
                        style={{ marginLeft: "-15px" }}
                      >
                        DIGITAL RADIO AS A SERVICE
                      </NavLink>
                    </Menu.Item>
                    {/* <Menu.Item key="6">
                      <NavLink
                        className="nav-link"
                        to="/solution-automotive"
                        activeClassName={"nav-link--active"}
                        onClick={closeMobileMenu}
                        style={{ marginLeft: "-15px" }}
                      >
                        AUTOMATIVE
                      </NavLink>
                    </Menu.Item> */}
                  </SubMenu>
                </Menu>
              </li>
              <li className="nav-item web-ssub-menu">
                <Dropdown
                  overlay={servicemenu}
                  trigger={["hover"]}
                  placement="bottomCenter"
                >
                  <NavLink
                    to="/"
                    className="nav-link"
                    style={{ color: "#fff" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    Services
                  </NavLink>
                </Dropdown>
              </li>
              <li
                className="nav-item mobile-active mobile-service-sub-menu"
                style={{ padding: "0px 15px !important" }}
              >
                <Menu
                  style={{
                    width: 280,
                    paddingLeft: "34px !important",
                    marginLeft: "-8px",
                  }}
                  // defaultSelectedKeys={["1"]}
                  // defaultOpenKeys={["sub3"]}
                  mode="inline"
                >
                  <SubMenu
                    key="sub3"
                    title="SERVICES"
                    style={{ paddingLeft: "4px !important" }}
                  >
                    <SubMenu
                      key="sub4"
                      title="DEVICE ENGINEERING"
                      className="service-submenu2"
                      style={{ marginLeft: "-15px" }}
                    >
                      <Menu.Item key="7" style={{ marginLeft: "10px" }}>
                        <NavLink
                          className="nav-link"
                          to="/embeded"
                          activeClassName={"nav-link--active"}
                          onClick={closeMobileMenu}
                        >
                          EMBEDDED SYSTEMS
                        </NavLink>
                      </Menu.Item>

                      <Menu.Item key="8" style={{ marginLeft: "10px" }}>
                        <NavLink
                          className="nav-link"
                          to="/android"
                          activeClassName={"nav-link--active"}
                          onClick={closeMobileMenu}
                        >
                          ANDROID
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="9" style={{ marginLeft: "10px" }}>
                        <NavLink
                          className="nav-link"
                          to="/qt"
                          activeClassName={"nav-link--active"}
                          onClick={closeMobileMenu}
                        >
                          <span className="capitalize">Qt</span>
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="10" style={{ marginLeft: "10px" }}>
                        <NavLink
                          className="nav-link"
                          to="/digital-signal-processing"
                          activeClassName="nav-link--active"
                          onClick={closeMobileMenu}
                        >
                          DSP
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sub5"
                      title="DIGITAL ENGINEERING"
                      style={{ marginLeft: "18px" }}
                    >
                      <Menu.Item key="11" style={{ marginLeft: "-20px" }}>
                        <NavLink
                          className="nav-link"
                          to="/ai-ml"
                          activeClassName={"nav-link--active"}
                          onClick={closeMobileMenu}
                        >
                          AI & ML
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item
                        key="12"
                        style={{
                          paddingLeft: "0px !important",
                          marginLeft: "-20px",
                        }}
                      >
                        <NavLink
                          className="nav-link"
                          to="/bigdata"
                          activeClassName={"nav-link--active"}
                          onClick={closeMobileMenu}
                        >
                          BIGDATA & ANALYTICS
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="13" style={{ marginLeft: "-20px" }}>
                        <NavLink
                          className="nav-link"
                          to="/cloud"
                          activeClassName={"nav-link--active"}
                          onClick={closeMobileMenu}
                        >
                          CLOUD
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="14" style={{ marginLeft: "-20px" }}>
                        <NavLink
                          className="nav-link"
                          to="/microservices"
                          activeClassName={"nav-link--active"}
                          onClick={closeMobileMenu}
                        >
                          MICROSERVICES
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                    <SubMenu
                      key="sub6"
                      title="SILICON ENGINEERING"
                      style={{ marginLeft: "17px" }}
                    >
                      <Menu.Item key="15" style={{ marginLeft: "-20px" }}>
                        <NavLink
                          className="nav-link"
                          to="/semiconductor"
                          activeClassName={"nav-link--active"}
                          onClick={closeMobileMenu}
                        >
                          ASIC/FPGA DESIGN
                        </NavLink>
                      </Menu.Item>
                      <Menu.Item key="16" style={{ marginLeft: "-20px" }}>
                        <NavLink
                          className="nav-link"
                          to="/semiconductor"
                          activeClassName={"nav-link--active"}
                          onClick={closeMobileMenu}
                        >
                          DESIGN VERIFICATION
                        </NavLink>
                      </Menu.Item>
                    </SubMenu>
                  </SubMenu>
                </Menu>
              </li>
              <li className="nav-item web-ssub-menu">
                <Dropdown
                  overlay={industryMenu}
                  trigger={["hover"]}
                  placement="bottomCenter"
                >
                  <NavLink
                    to="/"
                    className="nav-link"
                    style={{ color: "#fff" }}
                    onClick={(e) => e.preventDefault()}
                  >
                    Industry
                  </NavLink>
                </Dropdown>
              </li>
              <li
                className="nav-item mobile-active mobile-sub-menu"
                style={{ padding: "0px 15px !important" }}
              >
                <Menu
                  style={{ width: 313, paddingLeft: "34px !important" }}
                  // defaultSelectedKeys={["1"]}
                  // defaultOpenKeys={["sub8"]}
                  mode="inline"
                  className="mobile-about-submenu"
                >
                  <SubMenu
                    key="sub8"
                    title="INDUSTRY"
                    style={{ paddingLeft: "34px" }}
                  >
                    <Menu.Item key="11">
                      <NavLink
                        className="nav-link"
                        to="/defense"
                        activeClassName="nav-link--active"
                        onClick={closeMobileMenu}
                      >
                        Defense Electronics
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="12">
                      <NavLink
                        className="nav-link"
                        to="/healthcare"
                        activeClassName="nav-link--active"
                        onClick={closeMobileMenu}
                      >
                        HealthCare
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="13">
                      <NavLink
                        className="nav-link"
                        to="/automotive"
                        activeClassName="nav-link--active"
                        onClick={closeMobileMenu}
                      >
                        AUTOMOTIVE
                      </NavLink>
                    </Menu.Item>
                    <Menu.Item key="14">
                      <NavLink
                        className="nav-link"
                        to="/media-boardcast"
                        activeClassName="nav-link--active"
                        onClick={closeMobileMenu}
                      >
                        Media & Broadcast
                      </NavLink>
                    </Menu.Item>
                  </SubMenu>
                </Menu>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/aboutus"
                  className="nav-link"
                  activeClassName="nav-link--active"
                  style={{ color: "#fff" }}
                  onClick={closeMobileMenu}
                >
                  About Us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/career"
                  className="nav-link"
                  activeClassName="nav-link--active"
                  style={{ color: "#fff" }}
                  onClick={closeMobileMenu}
                >
                  Careers
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/contact"
                  className="nav-link font-normal"
                  activeClassName="nav-link--active"
                  style={{ color: "#fff" }}
                  onClick={closeMobileMenu}
                >
                  Contact Us
                </NavLink>
              </li>
              {/* <li className="nav-item mobile-let-btn">
                <NavLink
                  to="#"
                  className="uppercase underline font-semibold talk-link"
                  onClick={showModal}
                >
                  Let's Talk
                </NavLink>
              </li> */}
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
      <ModalPopup show={isModalVisible} onClose={handleClose} />
      <ToastContainer />
    </>
  );
};
export default Navbar;