import React from "react";
import "../../assets/style/footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faLinkedin,
  faWhatsapp,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <>
      {/* <div className="flex flex-row flex-col justify-center items-center footer">
        <div className="container text-white py-8" style={{paddingLeft:'40px',paddingRight:'20px'}}>
          <Row gutter={[16, 24]}>
            {/* <Col lg={6} xs={24}>
              <h4 className="text-2xl text-white text-center pb-4">About OPTM</h4>
              <p className="text-lg text-white " style={{textAlign:'justify'}}>
                OptM is a technology-based company providing solutions across
                domains and having its headquarter in Bangalore. OptM has a
                conglomeration of technocrats having collective experience of 80
                years in different industries.
              </p>
            </Col> */}
      {/* <Col lg={12} xs={24} style={{ paddingLeft: "40px" }} className="">
              <h4 className="text-2xl text-white text-center pb-4">Quick Links</h4>
              <ul className="text-lg quick-link text-center">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/">Solution</Link>
                </li>
                <li>
                  <Link to="/">Services</Link>
                </li>
                <li>
                  <Link to="/">Industry</Link>
                </li>
                <li>
                  <Link to="/">About Us</Link>
                </li>
                <li>
                  <Link to="/">Contact Us</Link>
                </li>
              </ul>
            </Col>

            <Col lg={12} xs={24}>
              <h4 className="text-2xl text-white text-center pb-4" style={{paddingRight:'100px'}}> Address</h4>
              <Row gutter={[16, 24]}>
                <Col lg={12} xs={24}>
                  <ul className="text-lg">
                    <li style={{paddingBottom:'7px'}}><strong>INDIA : </strong></li>
                    <li>
                      OptM Media Solutions Private Ltd
                      <br /> 63, Ram Mandir Road, Basavanagudi, <br/> Bangalore - 560004
                    </li>
                    <li>Ph : +91-9886722627</li>
                  </ul>
                </Col>
                <Col lg={12} xs={24}>
                  <ul className="text-lg" style={{paddingLeft:'40px'}}>
                    <li style={{paddingBottom:'7px'}}><strong>USA : </strong></li>
                    <li>
                      OptM Media Solutions LLC <br />8 The Green, Suite #11568
                      Dover,
                      <br /> DE 19901
                    </li>
                    <li>Ph : +1-619 488 2137</li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div> */}
      <div className="flex flex-col flex-row justify-center items-center sticky-footer text-white">
        <div
          className="container lg:flex items-center"
          style={{ justifyContent: "space-between" }}
        >
          <div>
            <p className="text-lg pt-8 lg:pt-0">
              © Copyright 2021 OptM Private Limited. All rights reserved.
            </p>
          </div>
          <div className="float-end">
            <ul className="flex justify-center mb-0">
              <li>
                <a
                  href="https://www.facebook.com/OptM-Media-Solutions-Private-Limited-108753894137073"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon"
                >
                  <FontAwesomeIcon
                    icon={faFacebook}
                    style={{ fontSize: "21px", color: "#fff" }}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/optm-media-solutions-private-limited/mycompany/"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon"
                >
                  <FontAwesomeIcon
                    icon={faLinkedin}
                    style={{ fontSize: "21px", color: "#fff" }}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/optmsol/"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{ fontSize: "21px", color: "#fff" }}
                  />
                </a>
              </li>
              <li>
                <a
                  href="https://api.whatsapp.com/send?phone=919886722627"
                  target="_blank"
                  rel="noreferrer"
                  className="social-icon"
                >
                  <FontAwesomeIcon
                    icon={faWhatsapp}
                    style={{ fontSize: "21px", color: "#fff" }}
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
