import React, { useState } from "react";
import { useEffect } from "react";
import "../../assets/style/solution.scss";
import "../../assets/css/products.css";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";

const I_mx6 = () => {
  const [isShow, setIsShow] = useState(false);
  const handleClick = () => {
    setIsShow((x) => !x);
  };
  return (
    <>
      <div className="main-container">
        <div className="intro-container">
          <div className="img-slider">
            <img
              className="arrow"
              src="../images/automotive/L-arrow.png"
              width="50px"
              height="50px"
              onClick={() => handleClick()}
            />
            <div className="images-of-prod">
              <img
                src="../images/automotive/products-img/prod1-IMX6.png"
                width="500px"
                className={`prodimg1 ${isShow ? "show" : ""}`}
              />
              <img
                src="../images/automotive/products-img/prod1.2-IMX6.png"
                width="500px"
                className="prodimg2"
              />
            </div>
            <img
              className="arrow"
              src="../images/automotive/R-arrow.png"
              width="50px"
              height="50px"
              onClick={() => handleClick()}
            />
          </div>
          <div className="content-p1">
            <h2>
              Electric Vehicle Instrument Cluster for 2 & 3 Wheelers NXP-i.MX6
            </h2>
            <p>
              Developed based on <strong>NXP-i.MX6</strong> & Qt and Combining a
              full-colour TFT-display. EV Instrument Cluster has an outstanding
              HMI, the feature-rich, user-friendly display makes motorcycle
              riding a fascinating and safe experience with enhanced
              convenience. All vehicle related information is displayed with
              exceptional clarity and the interaction with the system is simple
              and intuitive, assisting the rider to stay well informed at all
              times.
            </p>
          </div>
        </div>
        <div className="spec-container">
          <h2>
            <strong>SPECIFICATIONS</strong>
          </h2>
          <div className="prod-tables">
            <h4>
              <strong>Hardware Specification</strong>
            </h4>
            <table>
              <tbody>
                <tr className="head">
                  <th>CPU</th>
                  <td>
                    <p>i.MX6 </p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Frequency</th>
                  <td>
                    <p>800 MHz</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>RAM Memory size</th>
                  <td>
                    <p>512MB</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>eMMC Flash Memory size</th>
                  <td>
                    <p>4GB</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Display</th>
                  <td>
                    <p>5 inch Colour TFT, Capacitive Touch</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Resolution</th>
                  <td>
                    <p>WVGA 800x480, 24-bit color</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Aspect ratio</th>
                  <td>
                    <p>16:9</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Wireless Connectivity</th>
                  <td>
                    <p>LTE, BLE v5.1, GPS</p>
                  </td>
                </tr>
                <tr className="head">
                  <th>Wired Connectivity</th>
                  <td>
                    <p>
                      CAN 2.0B, UART, SPI, I2C, RS232 - Full Duplex, USB 2.0
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="prod-tables">
            <h4>
              <strong>Software Specification</strong>
            </h4>
            <table>
              <tbody>
                <tr>
                  <th>Operating System</th>
                  <td>
                    <p>Embedded Linux</p>
                  </td>
                </tr>
                <tr>
                  <th>Kernel Version</th>
                  <td>
                    <p>5.4.124</p>
                  </td>
                </tr>
                <tr>
                  <th>Power-On Splash logo</th>
                  <td>
                    <p>Customizable</p>
                  </td>
                </tr>
                <tr>
                  <th>GUI Toolkit</th>
                  <td>
                    <p>Qt 5.12</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="prod-tables">
            <h4>
              <strong>
                Electrical Specification & Compliance Certifications
              </strong>
            </h4>
            <table>
              <tbody>
                <tr>
                  <th>Voltage range</th>
                  <td>
                    <p>8VDC – 15VDC</p>
                  </td>
                </tr>
                <tr>
                  <th>Short Circuit protection</th>
                  <td>
                    <p>18V</p>
                  </td>
                </tr>
                <tr>
                  <th>Reverse polarity protection</th>
                  <td>
                    <p>18V</p>
                  </td>
                </tr>
                <tr>
                  <th>Switching Spikes</th>
                  <td>
                    <p>ISO 7637-2:2004 LEVEL4</p>
                  </td>
                </tr>
                <tr>
                  <th>EMC</th>
                  <td>
                    <p>EN 13309:2010 ESA</p>
                  </td>
                </tr>
                <tr>
                  <th>ESD</th>
                  <td>
                    <p>ANSI/ASAE EP455 5.12</p>
                  </td>
                </tr>
                <tr>
                  <th>Radiated immunity</th>
                  <td>
                    <p>ES455 5.16</p>
                  </td>
                </tr>
                <tr>
                  <th>Conducted emissions</th>
                  <td>
                    <p>CISPR25 Level 3</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      <ReachOut />
    </>
  );
};

export default I_mx6;
