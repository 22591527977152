import React from "react";
import jobData from "./JobData";
import { DesktopOutlined, GlobalOutlined } from "@ant-design/icons";
const ProfileData = (props) => {
  if (jobData.jobId === "OPTMCR005") {
    return <li>{jobData.requiredSkills}</li>;
  }
  return (
    <>
      <div>
        <h2 className="text-2xl text-primary pb-2">
          <b>{props.jobName}</b>{" "}
          <span style={{ color: "#333", fontSize: "16px" }}>
            (Job Code: {props.jobCode})
          </span>
        </h2>
        <div className="lg:flex">
          <div
            className="text-lg"
            style={{ marginBottom: "15px", color: "#464444" }}
          >
            <DesktopOutlined
              style={{ fontSize: "20px", paddingRight: "4px" }}
            />{" "}
            {props.employeeStatus}
          </div>
          <div style={{ color: "#464444" }} className="lg:pl-8 pb-4">
            <GlobalOutlined style={{ fontSize: "20px", paddingRight: "4px" }} />{" "}
            Bangalore,India
          </div>
        </div>

        <h3 className="text-xl pb-2">
          <b>Experience</b>
        </h3>
        <p className="text-lg">{props.experience}</p>
        <br />
        <h3 className="text-xl pb-2">
          <b>Qualification</b>
        </h3>
        <p className="text-lg">{props.qualification}</p>
        <br />
        <h3 className="text-xl pb-2">
          <b>Roles & Responsibility</b>
        </h3>

        <ul className="responsibility-items">
          {props.responsibility &&
            props.responsibility.map((d, index) => {
              return <li key={index}>{d}</li>;
            })}
        </ul>
        <br />

        {props.requiredSkills && (
          <>
            <h3 className="text-xl pb-2">
              <b>Required Skills</b>
            </h3>
            <ul className="responsibility-items">
              {props.requiredSkills.map((d, index) => {
                return <li key={index}>{d}</li>;
              })}
            </ul>
            <br />
          </>
        )}

        {/* <p className="text-lg pb-4">
          <span className="font-bold">Employee Status :</span>
          {props.employeeStatus}
        </p> 
        <p className="text-lg">
          <span className="font-bold">Job Posting :</span> {props.jobPosting}
        </p>*/}
        <br />
      </div>
    </>
  );
};

export default ProfileData;
