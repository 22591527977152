import React from "react";
import "../../assets/style/services.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
import "../../assets/style/industry.scss";
// import TechnologyHeading from "../home/TechnologyHeading";
const MediaBoardcast = () => {
  return (
    <>
      <div className="flex items-center nextgen-section">
        <div className="container">
          <h1 className="text-white lg:text-4xl text-2xl uppercase">
            Media & BROADCAST
            <br className="xs-hidden" />
            SOLUTIONS
          </h1>
        </div>
      </div>

      <div className="container">
        <Row gutter={[16, 24]}>
          <Col lg={12}>
            <div className="what-we-offer lg:pb-8 ">
              <h4 className="font-semibold lg:text-3xl text-xl uppercase  lg:pt-16 pt-8">
                BROADCAST SOLUTIONS
              </h4>
              <p
                className="text-lg lg:py-4 align-justify"
                style={{ borderBottom: "none" }}
              >
                Convergence is changing the way in which consumers use
                communication services and consume content, as it is available
                on new platforms and on various wireless portable devices. At
                the same time, technological change has impacted on regulation
                and conditions of competition.
              </p>
              <p className="text-lg align-justify">
                With the penetration of new technologies, consumers access and
                view on audio-visual content have changed. It is being provided
                via multiple platforms such as analogue or digital terrestrial
                broadcasts, satellite, cable or Internet Protocol (IP) and
                Over-the-Top (OTT) television. Combined with significant
                broadband penetration, it increases the bandwidth and the
                proliferation of digital devices.
              </p>
            </div>
          </Col>
          <Col lg={10}>
            <img
              src="../images/broadcast/nextgen.png"
              alt=""
              className="lg:pt-16 lg:pl-8 pb-8"
              style={{ width: "450px", maxHeight: "415px !important" }}
            />
          </Col>
        </Row>
      </div>
      <div className="what-we-offer defense-offer pb-8">
        <div className="container">
          <h4 className="font-semibold lg:text-2xl text-xl uppercase pt-8 pb-4">
            Service Providers Across
          </h4>
          <Row gutter={[16, 24]}>
            <Col lg={10}>
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">SOC and Chipsets</h5>
                  <p className="text-lg">
                    Custom/Legacy Middleware Development /Porting/Sustenance
                  </p>
                  <p className="text-lg">
                    Interactive TV – MHEG, GEM, EBIF, Tru2Way
                  </p>
                  <p className="text-lg pt-2">
                    Applications Development – EPG, Remote Diagnostics, Games,
                    Targeted Ad, Audience Research, Media Player, Photos,
                    Browser, Home Networking
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={10} offset={2} className="mob-left">
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">Semiconductor companies</h5>
                  <p className="text-lg">
                    Codec Development -H.265, HEVC, AC4, H.264, WMV, MP3, AAC,
                    AC3
                  </p>
                  <p className="text-lg">
                    Board Support Packages, Device Drivers Development
                  </p>

                  <p className="text-lg pt-2">
                    Reference Design – ATSC, DVB-S/C/T, ARIB/ISDB
                  </p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={10}>
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4">
                    Original Equipment Manufacturers
                  </h5>
                  <p className="text-lg">
                    Broadcast App & framework development – AI, ML and Data
                    Science tools to build, deploy and maintain A/344 runtime
                    apps leveraging the ATSC 3.0 potential to full throttle
                  </p>
                  <p className="text-lg pt-2">
                    DTV/STB Middleware Development/ Porting/ Sustenance
                  </p>
                </div>
              </div>
            </Col>
            <Col lg={10} offset={2} className="mob-left">
              <div className="flex ai-offerings">
                <div className="lg:pl-4">
                  <h5 className="text-xl  pb-4"></h5>
                  <p className="text-lg pt-8">
                    Application Development – EPG, MR-DVR, VOD, Media Player,
                    Photos, Browser, Internet Video/Radio, IP Streaming, Home
                    Networking
                  </p>
                  <p className="text-lg pt-2">
                    Product Variants – ATSC3.0, OpenCable, DVB-S/C/T, ISDB-T/S,
                    Hospitality, Digital Photo Frames, Wireless TV
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in Broadcast solution." /> */}
      <ReachOut />
    </>
  );
};

export default MediaBoardcast;
