import React from "react";
import "../../assets/style/products.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import Navbar from "../header/Navbar";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const NextGenTV = () => {
  return (
    <>
      <div className="pb-8">
        <div className="flex items-center nextgen-tv-banner">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-xl ">
              NEXTGEN TV - ATSC 3.0
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="text-3xl font-bold text-center pb-4">NEXTGEN TV</h4>
            <p className="text-lg  leading-loose align-justify">
              Next Gen TV is an innovative, new broadcasting standard, ATSC 3.0, that combines the 
              accessibility of over-the-air television with the interactive features of the internet.
              In the context of 5G specifications (3GPP Release 16), new capabilities and functionality 
              has been introduced in the 3GPP standards to fulfill the use cases and requirements of a 
              5G broadcast system to enable distribution of linear TV and broadcast radio services over the 5G systems. 
              ATSC3.0 would serve as Service Delivery using 5G Broadcast for TV, Radio, IPTV and File-casting.
            </p>
            <p className="text-lg  leading-loose align-justify">
              OptM is working on NextGen TV ATSC 3.0 solution for leading 
              Government broadcast agency with key technology partners.
            </p>
          </div>
        </div>
        <div className="sub-title pb-8">
          <div className="container">
            <Row className="flex" gutter={[16, 24]} style={{alignItems: 'center'}}>
              <Col lg={10} xs={24} className="text-lg">
                <h4 className="font-semibold text-2xl uppercase pb-4">
                  DIGICASTER
                </h4>
                <p class="text-lg align-justify pt-1">
                  NextGen TV broadcast deployment made simpler on your own.
                </p>
                <p className="text-lg align-justify pt-1">
                  Enables broadcasters to speed up the transition of over-the-air delivery.
                </p >
                <p className="align-justify pt-1">
                  Manage media services, generate playlists, create schedules, 
                  playout the channel, and deliver the feed to any Transmitter (LPLT or HPHT).
                </p>
                <p className="text-lg align-justify pt-1">
                  Launch datacasting for educational and Digital Signage services etc via broadcast.
                </p>
                <p className="text-lg align-justify pt-1">
                  Broadcast workflow can be managed & scaled from any remote location 
                  with seamless orchestration.
                </p>
                <p className="text-lg align-justify pt-1">
                  Simple Web UI to ingest media files in multiple formats onto the AWS cloud
                </p>
              </Col>
              <Col lg={12} s={24}>
                <h4 className="font-semibold text-2xl text-mobile uppercase pb-4">
                </h4>
                <img
                  src="../images/nextgen-tv/digicaster.png"
                  alt=""
                  width="100%"
                  className="mb-padding-top pl-6"
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="homecaster-section pt-8">
          <div className="container">
            <Row className="flex" gutter={[16, 24]} style={{alignItems: 'center'}}>
              <Col lg={10} xs={24} className="text-lg">
                <h4 className="font-semibold text-2xl uppercase pb-4">
                  HOME GATEWAY
                </h4>
                <p class="text-lg align-justify pt-1">
                  Home Gateway for over-the-air ATSC 3.0 reception.
                </p>
                <p class="text-lg align-justify pt-1">
                  Provides in-home video / data distribution via Wi-Fi connectivity.
                </p>
                <p className="text-lg align-justify pt-1">
                  Recasting the content to numerous connected devices including Mobile, Tablets, Legacy DTVs and PCs.
                </p >
                <p className="align-justify pt-1">
                  Datacasting of the generic files, transmitted via ATSC3.0 Non-Real Time services.
                </p>
                <p className="text-lg align-justify pt-1">
                  ESG and Advanced Emergency Alerts.
                </p>
                <p className="text-lg align-justify pt-1">
                  Offline Channel Scanning and update of channel list, third party EPG data integration.
                </p>
              </Col>
              <Col lg={12} s={24}>
                <h4 className="font-semibold text-2xl text-mobile uppercase pb-4">
                </h4>
                <img
                  src="../images/nextgen-tv/homecaster.png"
                  alt=""
                  width="100%"
                  className="mb-padding-top pl-6"
                />
              </Col>
            </Row>
          </div>
        </div>

        <div className="atsc3-section pt-8">
          <div className="container">
            <Row className="flex" gutter={[16, 24]} style={{alignItems: 'center'}}>
              <Col lg={10} xs={24} className="text-lg">
                <h4 className="font-semibold text-2xl uppercase pb-4">
                  ATSC 3.0 STACK
                </h4>
                <p class="text-lg align-justify pt-1">
                  Linux and Android-based software stack for ATSC 3.0 set-top box, 
                  television, and datacast receiver devices.
                </p>
                <p className="text-lg align-justify pt-1">
                  Modular and extensible software stack with porting layer.
                </p >
                <p className="align-justify pt-1">
                  Integrate with different frontends and SOC dependent module.
                </p>
                <p className="text-lg align-justify pt-1">
                  Tiny layer to integrate and support third party media players.
                </p>
                <p className="text-lg align-justify pt-1">
                Supports dual tuners. extendable for multi tuners.
                </p>
                <p className="text-lg align-justify pt-1">
                  Supports both Route and MMTP protocols.
                </p>
              </Col>
              <Col lg={12} s={24}>
                <h4 className="font-semibold text-2xl text-mobile uppercase pb-4">
                </h4>
                <img
                  src="../images/nextgen-tv/atsc3-stack.png"
                  alt=""
                  width="100%"
                  className="mb-padding-top pl-6"
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in NextGen TV technology." /> */}
      <ReachOut />
    </>
  );
};

export default NextGenTV;
