import "./App.scss";
import ScrollToTop from "./Components/ScrollToTop";
import ContactUs from "./Components/contact/ContactUs";
import Home from "./Components/home/Home";
import "./assets/style/responsive.scss";
import "antd/dist/antd.css";
import "@fortawesome/fontawesome-svg-core/styles.css";
import Footer from "./Components/footer/Footer";
import NextGenTV from "./Components/products/NextGenTV";
import NextGenRadio from "./Components/products/NextGenRadio";
import NextGenMobile from "./Components/products/NextGenMobile";
import EVCluster from "./Components/products/EVCluster";
import AudioFocus from "./Components/products/AudioFocus";
import ATSC3 from "./Components/solutions/ATSC3";
import BAAS from "./Components/solutions/BAAS";
import DRMASS from "./Components/solutions/DRMAAS";
import Automotive from "./Components/solutions/Automotive";
import HealthCare from "./Components/industry/HealthCare";
import { Switch, Route } from "react-router-dom";
import Embedeed from "./Components/services/Embedeed";
import Android from "./Components/services/Android";
import QT from "./Components/services/QT";
import DigitalSignalProcessing from "./Components/services/DigitalSignalProcessing";
import AIML from "./Components/services/AIML";
import Bigdata from "./Components/services/BigData";
import Cloud from "./Components/services/Cloud";
import Microservices from "./Components/services/Microservices";
import Semiconductor from "./Components/services/Semiconductor";
import DefenseElectronics from "./Components/industry/Defense";
import MediaBoardcast from "./Components/industry/MediaBoardcast";
import Career from "./Components/career/Career";
import AboutUs from "./Components/About/AboutUs";
import JobDescription from "./Components/career/JobDescription";
import SolutionAutomotive from "./Components/solutions/SolutionAutomotive";
// import ModalPopup from "./Components/header/ModalPopup";
import Navbar from "./Components/header/Navbar";
import Product1_imx6 from "./Components/products/i.MX6";
import Product2_imxrt from "./Components/products/i.MXRT";
function App(props) {
  return (
    <div className="App">
      <Navbar />
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home}></Route>
          <Route exact path="/nextgen-tv" component={NextGenTV}></Route>
          <Route exact path="/nextgen-radio" component={NextGenRadio}></Route>
          <Route exact path="/nextgen-mobile" component={NextGenMobile}></Route>
          <Route exact path="/ev-cluster" component={EVCluster}></Route>
          <Route
            exact
            path="/ev-cluster/i.MX6"
            component={Product1_imx6}
          ></Route>
          <Route
            exact
            path="/ev-cluster/i.MXRT"
            component={Product2_imxrt}
          ></Route>
          <Route exact path="/audio-focus" component={AudioFocus}></Route>
          <Route exact path="/atsc" component={ATSC3}></Route>
          <Route exact path="/baas" component={BAAS}></Route>
          <Route exact path="/drmaas" component={DRMASS}></Route>
          <Route exact path="/automotive" component={Automotive}></Route>
          <Route
            exact
            path="/solution-automotive"
            component={SolutionAutomotive}
          ></Route>
          <Route exact path="/healthcare" component={HealthCare}></Route>
          <Route exact path="/embeded" component={Embedeed}></Route>
          <Route exact path="/android" component={Android}></Route>
          <Route exact path="/qt" component={QT}></Route>
          <Route
            exact
            path="/job-description/:jobId"
            component={JobDescription}
          ></Route>
          <Route
            exact
            path="/digital-signal-processing"
            component={DigitalSignalProcessing}
          ></Route>
          <Route exact path="/ai-ml" component={AIML}></Route>
          <Route exact path="/bigdata" component={Bigdata}></Route>
          <Route exact path="/cloud" component={Cloud}></Route>
          <Route exact path="/microservices" component={Microservices}></Route>
          <Route exact path="/semiconductor" component={Semiconductor}></Route>
          <Route exact path="/defense" component={DefenseElectronics}></Route>
          <Route
            exact
            path="/media-boardcast"
            component={MediaBoardcast}
          ></Route>
          <Route exact path="/contact" component={ContactUs}></Route>
          <Route exact path="/career" component={Career}></Route>
          <Route exact path="/aboutus" component={AboutUs}></Route>
        </Switch>
      </ScrollToTop>
      <Footer />
    </div>
  );
}

export default App;
