import React, { useState, useEffect } from "react";
import Navbar from "../header/Navbar";
import "../../assets/style/jobdescription.scss";
import { Button } from "antd";
import { CaretLeftOutlined } from "@ant-design/icons";
import ProfileData from "./ProfileData";
import jobData from "./JobData";
import ApplyModal from "./ApplyModal";
import { Link } from "react-router-dom";
const JobDescription = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [jobDetail, setJobDetail] = useState({});
  const jobApplication = (e) => {
    setIsModalVisible(true);
  };

  const handleClose = (e) => {
    setIsModalVisible(false);
  };

  // const filteredData = jobData.filter((data, index) => {
  //   return data.jobId === props.jobId;
  // });
  useEffect(() => {
    const job = jobData.filter((data, index) => {
      return data.jobId === props.match.params.jobId;
    });
    setJobDetail(job[0]);
  }, [props.match.params.jobId]);
  return (
    <>
      {/* <div className="flex items-center job-section">
        <div className="container">
          <h1 className="text-white font-bold lg:text-5xl text-2xl uppercase">
            JOB DESCRIPTION
          </h1>
        </div>
      </div> */}
      <div className=" bg-grey py-16 pt-32">
        <div className="container lg:flex">
          <div className="left-content">
            <ProfileData
              key={jobDetail.jobId}
              jobName={jobDetail.jobName}
              jobCode={jobDetail.jobId}
              experience={jobDetail.experience}
              qualification={jobDetail.qualification}
              requiredSkills={jobDetail.requiredSkills}
              responsibility={jobDetail.responsibility}
              employeeStatus={jobDetail.employeeStatus}
              jobPosting={jobDetail.jobPosting}
            />
            {/* {jobData.map((filteredData) => {
              return (
                <ProfileData
                  key={jobDetail.jobId}
                  jobName={jobDetail.jobName}
                  experience={jobDetail.experience}
                  qualification={jobDetail.qualification}
                  requiredSkills={jobDetail.requiredSkills}
                  responsibility={jobDetail.responsibility}
                  employeeStatus={jobDetail.employeeStatus}
                  jobPosting={jobDetail.jobPosting}
                />
              );
            })} */}
            <h3 className="text-xl pb-2">
              <b>About OPTM</b>
            </h3>
            <p className="text-lg align-justify">
              OptM is a premier technology-based services company having
              headquarters in Bangalore and presence in Denver, US. Our
              comprehensive portfolio of services coupled with state-of-the-art
              technology building blocks, provides our customers with advantages
              of top-of-the-line technologies to realize their time critical and
              high-performance product development and deployment. We work
              closely with customers to solve their complex business challenges.
              Together we realize a new class of next generation products &
              solutions to enable & drive growth.
            </p>
            <p
              className="text-grey"
              style={{ marginTop: "20px", textAlign: "right" }}
            >
              Posted On: {jobDetail.jobPosting}
            </p>
          </div>
          <div className="right-content">
            <Button
              className="btn btn-primary apply-now"
              onClick={jobApplication}
            >
              Apply Now
            </Button>
          </div>
        </div>
        <div className="container">
          <p className="mt-16 text-xl ">
            {" "}
            <Link to="/career" style={{ color: "#333", display: "flex" }}>
              <CaretLeftOutlined
                style={{ paddingTop: "7px", paddingRight: "10px" }}
              />{" "}
              Back to Job Listings
            </Link>
          </p>
        </div>
      </div>
      <ApplyModal
        show={isModalVisible}
        onClose={handleClose}
        additionalInfo={{
          jobCode: jobDetail.jobId,
          jobTitle: jobDetail.jobName,
        }}
      />
    </>
  );
};

export default JobDescription;
