import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const Clientele = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1800, //milliseconds
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 360,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };
  return (
    <>
      <div className="flex bg-grey justify-center items-center py-8 clientele">
        <div className="container">
          <h4 className="text-2xl uppercase font-semibold pb-8">
            Partnerships & Alliances
          </h4>
          <Slider {...settings}>
            <div>
              <img src="../images/Client-logo/ateme.png" alt="" />
            </div>
            <div>
              <img
                src="../images/Client-logo/cellink.png"
                alt=""
                style={{ width: "216px", height: "45px", marginTop: "7px" }}
              />
            </div>
            <div>
              <img src="../images/Client-logo/digicap.png" alt="" />
            </div>
            <div>
              <img src="../images/Client-logo/jio.png" alt="" />
            </div>
            <div>
              <img
                src="../images/Client-logo/nxp1.png"
                alt=""
                style={{ width: "140px", height: "130px", marginTop: "-15px" }}
              />
            </div>
            <div>
              <img
                src="../images/Client-logo/infineon.png"
                alt=""
                style={{ width: "180px", height: "90px", marginTop: "-5px" }}
              />
            </div>
            <div>
              <img
                src="../images/Client-logo/qt_service_partner.png"
                alt=""
                style={{ width: "150px", height: "100px", marginTop: "-10px" }}
              />
            </div>
            <div>
              <img
                src="../images/Client-logo/NDS-Motors.png"
                alt=""
                style={{ height: "90px", marginTop: "-5px" }}
              />
            </div>

            <div>
              <img src="../images/Client-logo/harman.png" alt="" />
            </div>
            <div>
              <img
                src="../images/Client-logo/stryker.png"
                alt=""
                style={{ width: "150px", height: "40px", marginTop: "12px" }}
              />
            </div>
            <div>
              <img
                src="../images/Client-logo/bgauss.png"
                alt=""
                style={{ height: "50px", marginTop: "5px" }}
              />
            </div>
            <div>
              <img src="../images/Client-logo/tata.png" alt="" />
            </div>
            <div>
              <img src="../images/Client-logo/vocera.png" alt="" />
            </div>

            <div>
              <img
                src="../images/Client-logo/saankhya-labs.png"
                alt=""
                style={{ marginTop: "-8px" }}
              />
            </div>
            <div>
              <img src="../images/Client-logo/dolby.png" alt="" />
            </div>
            <div>
              <img
                src="../images/Client-logo/hpe.png"
                alt=""
                style={{ width: "180px", height: "117px", marginTop: "-25px" }}
              />
            </div>
            <div>
              <img src="../images/Client-logo/DTVKit.png" alt="" />
            </div>
            <div>
              <img src="../images/Client-logo/tasl.png" alt="" />
            </div>
            <div>
              <img src="../images/Client-logo/fraunhofer.png" alt="" />
            </div>
            <div>
              <img src="../images/Client-logo/verimatrix.png" alt="" />
            </div>

            <div>
              <img src="../images/Client-logo/renesas.png" alt="" />
            </div>
            <div>
              <img
                src="../images/Client-logo/jupiterMobility.jpg"
                alt=""
                style={{ width: "110px", height: "80px", marginTop: "-10px" }}
              />
            </div>
            <div>
              <img
                src="../images/Client-logo/mediatek.svg"
                alt=""
                style={{ height: "40px", marginTop: "10px" }}
              />
            </div>
            <div>
              <img
                src="../images/Client-logo/amlogic.jpg"
                alt=""
                style={{ height: "50px", marginTop: "4px" }}
              />
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Clientele;
