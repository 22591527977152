import React from "react";
import "../../assets/style/services.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const AIML = () => {
  return (
    <>
      <div className="pb-8">
        <div className="flex items-center aiml-section">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-2xl uppercase">
              complex analytics
              <br className="xs-hidden" /> with AI And ML
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="uppercase text-3xl font-bold text-center pb-4">
              AI ML @ EDGE
            </h4>
            <p className="text-lg   align-justify">
              Massive amounts of data combined with the cost and latency of
              cloud computing demand a robust edge computing solution. To
              perform complex data analytics and execute complicated machine
              learning models on high volume, high velocity streaming sensor
              data in a limited memory footprint requires competency both in
              embedded devices and AI ML model.
            </p>
            <p className="text-lg   pt-2 align-justify">
              From building extremely low footprint and low power models on
              constrained endpoints to bringing full-fledged, high-accuracy
              algorithms on edge devices, we bring the right solutions for our
              customer’s applications.
            </p>
          </div>
        </div>
        <div className="what-we-offer pb-8">
          <div className="container">
            <h4 className="font-semibold text-2xl uppercase pb-4">Benefits</h4>
            <Row className="flex justify-center items-center" gutter={[16, 24]}>
              <Col lg={10} xs={24} offset={2} className="text-lg">
                <p>Algorithm development</p>
                <p className="pt-2">Architecture definition</p>
                <p className="pt-2">Digital Signal Processing design review</p>
                <p className="pt-2">
                  Co-processing, device, and system partitioning
                </p>
              </Col>
              <Col lg={10} xs={24} offset={2} className="text-lg ">
                <p>FPGA implementation</p>
                <p className="pt-2">MATLAB and Simulink simulation</p>
                <p className="pt-2">Verification and debugging</p>
                <p className="pt-2">
                  Optimization for cost, performance, and efficiency
                </p>
              </Col>
            </Row>
          </div>
        </div>

        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="uppercase text-2xl font-bold text-center pb-4">
              AI ML @ CLOUD
            </h4>
            <p className="text-lg  leading-loose align-justify">
              Cloud infrastructure offers cloud with AI & machine learning, the
              extensibility, resilience & procurement makes it an ideal choice
              for building an AI/ML pipeline that bring benefits for end-to-end
              system. This amalgamation is far better than curling through the
              many manual algorithms and paves the way for digital innovation
              and enables to put data centric vision in to practice and achieve
              organization goal at faster pace.
            </p>
            <p className="text-lg  leading-loose align-justify">
              With the years of expertise in providing state-of-the-art AI &ML
              solutions, we understand our customer’s requirements and
              constraints and build just the right solutions for them. From
              traditional machine learning to efficient deep learning networks,
              we use the right model architecture, frameworks, and tools to
              build a reliable and scalable solution for your application
              whether on Cloud and Edge devices.
            </p>
          </div>
        </div>
        {/* <div className="what-we-offer">
          <div className="container">
            <h4 className="font-semibold text-2xl uppercase pb-4">Benefits</h4>
            <Row className="flex justify-center items-center" gutter={[16, 24]}>
              <Col lg={10} xs={24} offset={2} className="text-lg">
                <p>Algorithm development</p>
                <p className="pt-2">Architecture definition</p>
                <p className="pt-2">Digital Signal Processing design review</p>
                <p className="pt-2">Co-processing, device, and system partitioning</p>
              </Col>
              <Col lg={12} xs={24} className="text-lg ">
                <p>FPGA implementation</p>
                <p className="pt-2">MATLAB and Simulink simulation</p>
                <p className="pt-2">Verification and debugging</p>
                <p className="pt-2">Optimization for cost, performance, and efficiency</p>
              </Col>
            </Row>
          </div>
        </div> */}
        <div className="what-we-offer" style={{ background: "#f7f8f9" }}>
          <div className="container">
            <h4 className="font-semibold text-2xl uppercase py-8">AI FLOW</h4>
            <img src="../images/ai-ml/data-flow.png" alt="" />
            <Row>
              <Col lg={6} className="text-center">
                <h5 className="text-primary-light py-4 text-lg ">
                  AI potential assessment
                </h5>
                <p
                  className="text-lg"
                  style={{ width: "84%", paddingLeft: "40px" }}
                >
                  Understanding business needs, data and constraints
                </p>
              </Col>
              <Col lg={6} className="text-center">
                <h5 className="text-primary-light py-4 text-lg ">
                  Product Definition
                </h5>
                <p
                  className="text-lg"
                  style={{ width: "84%", paddingLeft: "33px" }}
                >
                  Defining product functionality and integrating data
                </p>
              </Col>
              <Col lg={6} className="text-center pb-4">
                <h5 className="text-primary-light py-4 text-lg ">Go Live</h5>
                <p
                  className="text-lg"
                  style={{ width: "84%", paddingLeft: "40px" }}
                >
                  Building and deploying a customized AI product
                </p>
              </Col>
              <Col lg={6} className="text-center">
                <h5 className="text-primary-light py-4 text-lg ">
                  Feedback and improvement
                </h5>
                <p
                  className="text-lg"
                  style={{ width: "84%", paddingLeft: "40px" }}
                >
                  Monitoring and improving with live data
                </p>
              </Col>
            </Row>
          </div>
        </div>

        <div className="what-we-offer">
          <div className="container">
            <h4 className="font-semibold text-2xl uppercase py-8 semi-heading">
              OUR GAMUT OF OFFERINGS
            </h4>
            <Row gutter={[16, 24]}>
              <Col lg={10}>
                <div className="flex ai-offerings">
                  <img
                    src="../images/ai-ml/usecase.png"
                    alt=""
                    width="60"
                    height="60"
                  />

                  <div className="pl-4">
                    <h5 className="text-xl ">Use case discovery</h5>
                    <p className="text-lg align-justify">
                      We help identify opportunities where data can be harnessed
                      through AI and convert them into valuable
                      product-dropdown-list
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img
                    src="../images/ai-ml/hosting.png"
                    alt=""
                    width="60"
                    height="60"
                  />

                  <div className="pl-4">
                    <h5 className="text-xl ">
                      Hosting and Maintenance support
                    </h5>
                    <p className="text-lg align-justify">
                      We help users to host their models/ applications on cloud/
                      on premise servers and provide all the required
                      maintenance support for the backend infrastructure.
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img
                    src="../images/ai-ml/solution-edge.png"
                    alt=""
                    width="60"
                    height="60"
                  />

                  <div className="pl-4">
                    <h5 className="text-xl ">Solution engineering</h5>
                    <p className="text-lg align-justify">
                      We enable Cloud/Edge integration of AI and enterprise
                      stack. Embedded AI for localized AI Solutions
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img src="../images/ai-ml/bespoke.png" alt="" width="60px" />

                  <div className="pl-4">
                    <h5 className="text-xl ">Bespoke Applications</h5>
                    <p className="text-lg align-justify">
                      We create AI enabled use cases in numerous verticals,
                      contextualized to the needs of the customer. Our top notch
                      experts are well versed in building and training any
                      custom models according to customer's requirements
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={10} offset={2}>
                <div className="flex ai-offerings">
                  <img
                    src="../images/ai-ml/feasibility.png"
                    alt=""
                    width="60"
                    height="60"
                  />

                  <div className="pl-4">
                    <h5 className="text-xl ">Feasibility Study</h5>
                    <p className="text-lg align-justify">
                      We provide cost effective feasibility study and support in
                      developing prototypes or POCs to test the viability of ML
                      models to address the requirements
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img
                    src="../images/ai-ml/solution.png"
                    alt=""
                    width="60"
                    height="60"
                  />

                  <div className="pl-4">
                    <h5 className="text-xl ">Solution Accelerators</h5>
                    <p className="text-lg align-justify">
                      We provide reusable and customizable AI stack to
                      accelerate AI development and deployment. Ready-to-use
                      pre-trained models comprehensive library of trainable AI
                      building blocks, advanced programmatic and API interfaces
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img
                    src="../images/ai-ml/power.png"
                    alt=""
                    width="60"
                    height="60"
                  />

                  <div className="pl-4">
                    <h5 className="text-xl ">AI competency power packs</h5>
                    <p className="text-lg align-justify">
                      We will also help create self sustaining AI talent pools
                      that build, expand, enhance and sustain a new operating
                      model driven by AI and ML.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in AI & ML technology." /> */}
      <ReachOut />
    </>
  );
};

export default AIML;
