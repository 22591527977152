import React, { useState } from "react";
import { Button, Card } from "antd";

import { Link } from "react-router-dom";
import ApplyModal from "./ApplyModal";

const JobCard = (props) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const jobApplication = () => {
    setIsModalVisible(true);
  };

  const handleClose = (e) => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Card
        key={props.jobId}
        title={props.jobName}
        headStyle={{
          fontWeight: "bold",
          borderRadius: "0px",
          fontSize: "20px",
        }}
      >
        <div className="card-body">
          <p className="text-md job-code">Job Code: {props.jobId}</p>

          <p style={{ fontSize: "17px" }}>
            {props.experience} | <span>{props.employeeStatus}</span>
          </p>
        </div>
        <div className="flex justify-start py-4 ">
          <Button className="btn apply-btn mr-4" onClick={jobApplication}>
            Apply Now
          </Button>
          <Link to={`/job-description/${props.jobId}`}>
            <Button className="btn apply-btn mr-4">View JD</Button>
          </Link>
        </div>
      </Card>
      <ApplyModal 
        show={isModalVisible} 
        onClose={handleClose} 
        additionalInfo={{jobCode:props.jobId, jobTitle:props.jobName}} />
    </>
  );
};
export default JobCard;
