import React from "react";
import ContactForm from "./ContactForm";
import { Row, Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import Navbar from "../header/Navbar";
const ContactUs = () => {
  return (
    <>
      <div className="flex items-center contact-section">
        <div className="container">
          <h1 className="text-white font-bold lg:text-5xl text-2xl">
            Contact us
          </h1>
        </div>
      </div>
      <div className="contact-bg">
        <div className="flex lg:block md:flex-row flex-col justify-center itmes-center py-4 pt-4">
          <ContactForm />
        </div>
        <div className="lg:pb-4 flex flex-col justify-center itmes-center text-center connect-us">
          <h4 className="lg:text-4xl text-xl uppercase mb-4">
            Get In Touch
          </h4>
        </div>
        <div className=" flex lg:block md:flex-row flex-col justify-center itmes-center lg:text-center pb-8">
          <div className="container mob-container">
            <Row>
              <Col lg={12} xs={24}>
                <iframe
                  title="map"
                  className="mob-map"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2012.7643936380919!2d77.57703841260981!3d12.943288347253096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x5592e0168b9073c2!2zMTLCsDU2JzM1LjciTiA3N8KwMzQnMzkuNSJF!5e0!3m2!1sen!2sus!4v1655350874883!5m2!1sen!2sus"
                  allowfullscreen=""
                  style={{}}
                  loading="lazy"
                ></iframe>
              </Col>
              <Col lg={12} xs={24}>
                <div className=" flex flex-row flex-col itmes-center  address-line h-100">
                  <ul className="pt-8 text-left leading-loose">
                    <div className="flex ">
                      <li className="flex">
                        <div
                          className="font-bg xs-hidden"
                          style={{ width: "42px" }}
                        >
                          <FontAwesomeIcon
                            icon={faMapMarkerAlt}
                            style={{
                              fontSize: "21px",
                              color: "#fff",
                              marginTop: "12px",
                            }}
                          />
                        </div>

                        <div>
                          <strong> India : </strong>OptM Media Solutions Private
                          Ltd
                          <br /> #21/1 Church Street Road, Basavanagudi,
                          <br /> Bangalore 560004.
                          <br />
                          <br />
                          <strong> USA : </strong>OptM Media Solutions LLC
                          <br /> #8 The Green, Suite A Dover,
                          <br /> DE 19901.
                        </div>
                      </li>
                    </div>
                    {/* <div className="flex ">
                    <img
                      src="../../../images/telephone.png"
                      alt=""
                      style={{ margin: "11px 15px", height: "20px" }}
                    />
                    <li>+1234567890</li>
                  </div> */}
                    <div className="flex ">
                      <li className="flex pt-4">
                        <div className="font-bg xs-hidden">
                          <FontAwesomeIcon
                            icon={faEnvelopeOpen}
                            style={{
                              fontSize: "21px",
                              color: "#fff",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                        <a
                          className="underline"
                          href="mailto:talk2us@optmsol.com"
                        >
                          <strong className="font-bold">info</strong>
                          @optmsol.com
                        </a>
                      </li>
                    </div>
                    <div className="flex ">
                      <li className="flex pt-4">
                        <div className="font-bg xs-hidden">
                          <FontAwesomeIcon
                            icon={faPhoneAlt}
                            style={{
                              fontSize: "21px",
                              color: "#fff",
                              marginTop: "10px",
                            }}
                          />
                        </div>
                        <div>
                          <strong>India : </strong> +91-9886722627 <br />
                          <strong>USA : </strong> +1-6194882137
                        </div>
                      </li>
                    </div>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
    </>
  );
};

export default ContactUs;
