import React from "react";
import "../../assets/style/services.scss";
import Clientele from "../home/Clienttele";
import MembershipSlider from "../home/Membership";
import ReachOut from "../home/ReachOut";
import { Row, Col } from "antd";
// import TechnologyHeading from "../home/TechnologyHeading";
const Bigdata = () => {
  return (
    <>
      <div className="pb-8">
        <div className="flex items-center bigdata-section">
          <div className="container">
            <h1 className="text-white font-bold lg:text-5xl text-2xl uppercase">
              build scalable
              <br /> big data solutions
            </h1>
          </div>
        </div>
        <div className="flex py-8 justify-center items-center lg:text-center embeded-system">
          <div className="container">
            <h4 className="uppercase text-3xl font-bold text-center pb-4">
              BIG DATA & ANALYTICS
            </h4>
            <p className="text-lg  leading-loose pb-2 align-justify">
              Big data analytics along with artificial intelligence (AI) and
              machine learning (ML) is able to address complex business
              challenges. This is the reason why enterprises that differentiate,
              outperform, and adapt to customer needs faster than their
              competitors are relying heavily on big data analytics. Big data
              analytics helps businesses convert their historical and real-time,
              traditional, and big data into actionable insights. They see how
              the purposeful, systematic exploitation of big data, coupled with
              analytics, reveals opportunities for better business outcomes.
            </p>
            <p className="text-lg leading-loose align-justify">
              OptM is backed by powerful big data analytics tools and help its
              clients to break data silos and unearth valuable insights to act
              upon. Our advanced data integration model enables you to aggregate
              structured, semi-structured, and unstructured data from various
              touch points to provide a 360-degree view of disparate data
              sources and create custom analytical views with flexibility and
              ease of use that simply didn’t exist before. Our big data
              scientists make data science serve the enterprises striving to
              harness complex data processing operations and analyze, interpret,
              and present data in meaningful and actionable ways.
            </p>
          </div>
        </div>

        <div className="what-we-offer">
          <div className="container">
            <h4 className="font-semibold lg:text-2xl text-xl uppercase py-8">
              BIG DATA DEVELOPMENT OFFERING
            </h4>
            <Row gutter={[16, 24]}>
              <Col lg={10}>
                <div className="flex ai-offerings">
                  <img src="../images/bigdata/bigdata.png" alt="" width="60" />

                  <div className="pl-4">
                    <h5 className="text-xl ">Big Data Integration Services</h5>
                    <p className="text-lg align-justify">
                      Effectively integrate dispersed data silos into a single
                      source of truth to fuel decision-making and support
                      analytics efforts.
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img
                    src="../images/bigdata/data-ingestion.png"
                    alt=""
                    width="60"
                  />
                  <div className="pl-4">
                    <h5 className="text-xl align-justify">Data ingestion</h5>
                    <p className="text-lg">
                      With high-performance data connectors and best-in-class
                      bespoke ingestion tools, we enable enterprises to
                      effectively connect with various data sources, including
                      on-premise, cloud, and IoT, — no limits on data types and
                      volume.
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img
                    src="../images/bigdata/trusted-data.png"
                    alt=""
                    width="60"
                  />
                  <div className="pl-4">
                    <h5 className="text-xl ">Ensuring security and privacy</h5>
                    <p className="text-lg align-justify">
                      Implement advanced encryption and role management so that
                      you can deliver highly secure and trusted data across all
                      applications.
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img
                    src="../images/bigdata/migration.png"
                    alt=""
                    width="60"
                  />
                  <div className="pl-4">
                    <h5 className="text-xl ">Data migration</h5>
                    <p className="text-lg align-justify">
                      Transfer your legacy data to the cloud or switch to a new
                      storage technology, we make the migration process painless
                      and secure, with no interruption to your business.
                    </p>
                  </div>
                </div>
              </Col>
              <Col lg={10} offset={2}>
                <div className="flex ai-offerings">
                  <img
                    src="../images/bigdata/handling-data.png"
                    alt=""
                    width="60"
                  />
                  <div className="pl-4">
                    <h5 className="text-xl ">Handling Data variety</h5>
                    <p className="text-lg align-justify">
                      Ingest data from CRM, ERP, Marketing, sales, and more
                      etc., To enable detailed insights. Further integrate data
                      from web, social media, etc. for the most complete
                      picture.
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img
                    src="../images/bigdata/transmission.png"
                    alt=""
                    width="60"
                  />
                  <div className="pl-4">
                    <h5 className="text-xl ">Data transformation</h5>
                    <p className="text-lg align-justify">
                      State-of-the-art ETL and ELT pipelines that streamline
                      data cleansing and aggregation for further analysis. Our
                      big data engineers leverage AI and ML to discover new
                      rules for data transformation and automate metadata
                      management, resulting in reduced costs and improved
                      efficiency.
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img
                    src="../images/bigdata/data-warehouse.png"
                    alt=""
                    width="60"
                  />
                  <div className="pl-4">
                    <h5 className="text-xl ">Data warehousing</h5>
                    <p className="text-lg align-justify">
                      Integrate your data into a next-gen data warehouse
                      platform able to handle massive amounts of unstructured
                      and semi-structured data.
                    </p>
                  </div>
                </div>
                <div className="flex ai-offerings">
                  <img src="../images/bigdata/bi.png" alt="" width="60" />
                  <div className="pl-4">
                    <h5 className="text-xl ">BI and Analytics</h5>
                    <p className="text-lg align-justify">
                      Enable custom AI-powered analytics solution to discover
                      deep data insights and empower your stakeholders with a
                      single source of truth to support decision making.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <Clientele />
      <MembershipSlider />
      {/* <TechnologyHeading text="Take the next step in Bigdata." /> */}
      <ReachOut />
    </>
  );
};

export default Bigdata;
